.custom_container {
	max-width: 630px;
	width: 100%;
	margin: 0px 80px 0px 110px;
}

.scp_imprvoment tr {
	text-align: center;
}

.scp_imprvoment td {
	font-weight: bold;
}

.table_header {
	color: #294d8f;
	font-size: 15px;
}

.teble_head {
	background-color: #294d8f;
	color: aliceblue;
}

.hr_line {
	height: 1px;
	width: 180px;
	border-width: 0;
	color: black;
	background-color: black;
	margin-top: 60px;
}

.footer_points {
	font-size: 10px;
	line-height: 14px;
	list-style-type: none;
	color: black;

	margin-bottom: 10px;
}

.footer_images_table_3 {
	margin-left: 0px;
	margin-top: 22px;
	display: flex;
	align-items: self-end;
}

.footer_images_table {
	margin-left: 5px;
	margin-top: 5px;
}

.footer_images_text {
	font-size: 9px;
	color: #294d8f;
}

.note_txt {
	font-weight: bold;
	font-size: 16px;
	color: #3b7e80;
	font-family: 'Roboto';
}

.note_desc {
	font-size: 14px;
	color: black;
	font-family: 'Roboto';
}

.sign_box {
	padding-top: 100px;
	display: flex;
	justify-content: space-between;
}

.submitd_box {
	width: 250px;
	height: 160px;
	border: 1px solid black;
	padding: 4px 12px;
}

.sign_line {
	padding-top: 9px;
	opacity: 1;
	margin: 1rem 0 0 0;
}

.submitdby_txt {
	line-height: 2px;
	font-size: 12px;
	padding: 15px 0 0px 0;
	color: #3b7e80;
	font-family: 'Roboto';
}

.submitdby_txt_right {
	line-height: 2px;
	font-size: 12px;
	padding: 15px 0 17px 0;
	color: #3b7e80;
	font-family: 'Roboto';
}

.teconslt_txt {
	font-size: 9px;
	color: black;
	font-family: 'Roboto';
	text-transform: capitalize;
}

.ste_name {
	font-size: 13px;
	font-weight: bold;
	color: black;
	font-family: 'Roboto';
	font-weight: bold;
	text-transform: capitalize;
}

.ste_txt {
	font-size: 9px;
	line-height: 1px;
	color: black;
	font-family: 'Roboto';
}

.footer_images_table_5 {
	margin-left: 0px;
	margin-top: 204px;
	display: flex;
	align-items: self-end;
	width: 50px;
}
