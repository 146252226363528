.labelcheck {
	cursor: pointer;
	border: 2px solid #707070;
	border-radius: 13px;
	background: #fffcf4;
}

.labelcheck img {
	box-shadow: 0 0 0 10px rgba(230, 69, 69, 0);
	transition: box-shadow 0.1s;
}

.labelcheck input {
	display: none;
}

.labelcheck input:checked + img {
	box-shadow: 0 0 0 3px #294d8f;
}
.galleryFlex {
	display: flex !important;
	justify-content: left;
	flex-wrap: wrap;
	max-height: 465px;
	overflow-y: auto;
	padding-top: 8px;
	padding-left: 10px;
}
.galleryFlex img {
	height: 180px;
	width: 200px;
	object-fit: contain;
}
.galleryFlex img {
	vertical-align: super;
	border-radius: 10px;
	border-bottom: 2px solid #707070;
}
.galleryFlex::-webkit-scrollbar {
	width: 10px;
}

.galleryFlex::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px grey;
	border-radius: 10px;
}

.galleryFlex::-webkit-scrollbar-thumb {
	background: #294d8f !important;
	border-radius: 10px;
}
.galleryFlex > div {
	margin: 0px 12px 8px 0px;
}
.galleryImage {
	border: 3px solid #ffde9b;
	padding: 2px;
	width: 200px;
	height: 140px;
	object-fit: contain;
}
.imageCaption {
	font-size: 16px;
	border: 1px solid #ced4da;
	padding-left: 8px;
	padding-right: 8px;
	outline: none;
	width: 90%;
	background: transparent;
	padding-top: 4px;
	padding-bottom: 4px;
	border-radius: 5px;
}
.labelcheck {
	position: relative;
}
.deleteImage {
	position: absolute;
	top: 6px;
	right: 6px;
	width: 33px !important;
	height: 33px !important;
	color: red;
	padding: 0px;
	cursor: pointer;
}
.selectGalleryImg div {
	width: 200px;
	height: 300px;
}
.textOverflow {
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	padding: 0px 10px;
	padding-bottom: 10px;
	color: #6d6d6d;
	font-size: 13px;
	font-weight: 400;
	display: -webkit-box;
	max-width: 200px;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	height: 43px;
}
@media only screen and (max-width: 768px) {
	.galleryImage {
		width: 200px;
		height: 140px;
		object-fit: contain;
	}
	.textOverflow {
		width: 100%;
	}
	.galleryFlex > div {
		margin: 0px 8px 0px 0px;
	}
	.galleryFlex {
		display: flex;
		justify-content: left;
		flex-wrap: wrap;
		max-height: 460px;
		overflow-y: auto;
		padding-top: 8px;
		padding-bottom: 50px;
	}
	.deleteImage {
		position: absolute;
		top: 6px;
		right: 6px;
		width: 25px !important;
		height: 25px !important;
		padding: 0px;
		cursor: pointer;
		border-radius: 4px;
		border: none !important;
	}
	.galleryFlex > div {
		margin: 0px 10px 10px 0px;
		width: 46%;
	}
	.galleryFlex img {
		height: 170px;
		width: 200px;
		object-fit: cover;
	}
}
