.upload {
	padding: 35px 20px;
}

.upload h1 {
	font-weight: 700;
	font-size: 24px;
	color: #294d8f;
}

.upload p {
	color: #6d6d6d;
	font-size: 12px;
}

.signature {
	background-color: #ffffff;
	color: #294d8f;
	border: #294d8f 2px solid;
	padding: 8px 15px;
	font-size: 14px;
	border-radius: 10px;
	font-weight: 500;
	line-height: 1.5;
	margin: 2px;
	position: relative;
	display: inline-block;
	text-align: center;
	min-width: 300px;
	font-family: 'Robotomedium';
}

input[type='file'] {
	display: none;
}

.upload img {
	width: 14px;
	margin-right: 10px;
}
@media screen and (max-width: 450px) {
	.signature {
		min-width: 80px;
		padding: 8px 50px;
	}
}
