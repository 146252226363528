.chooseLanguage h1 {
	color: #294d8f;
	font-size: 24px;
	font-weight: 600;
	text-align: center;
}
.chooseLanguage p {
	color: #6d6d6d;
	font-size: 14px;
	text-align: center;
}
button.confirm {
	width: 100%;
	max-width: 300px;
}
