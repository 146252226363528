.mainContainer {
	display: flex;
	justify-content: space-evenly;
	flex-direction: column;
	background-color: #fffcf4;
}
.input {
	border-top: none !important;
	border-left: none !important;
	border-right: none !important;
	border-bottom: 1px solid gray !important;
	color: gray;
	background-color: #fffcf4;
	max-width: 200px;
	overflow: hidden;
}
.input:focus {
	outline: none;
}
.box {
	flex: 1;
	text-align: left;
}
.label {
	font-weight: bold;
	color: gray;
	white-space: nowrap;
	width: 100%;
	max-width: 200px;
}

.headingBold {
	color: black;
}
.phone-code {
	color: #999;
	pointer-events: none;
}
