.wraper {
	padding: 30px 25px;
}

.headertxt {
	font-weight: 700;
	font-size: 26px;
	color: #294d8f;
}
.subtxt {
	color: #6d6d6d;
	font-size: 14px;
	font-family: 'Roboto';
}

.name {
	color: #5a5a5a;
	font-family: 'Robotomedium';
	font-size: 24px;
	padding: 0px;
	margin: 0px;
}
.lessthan {
	padding-right: 10px;
}
.design {
	color: #5a5a5a;
	font-family: 'Roboto';
	font-size: 13px;
	padding: 0px;
	margin: 0px;
}
.cntry {
	color: #5a5a5a;
	font-family: 'Roboto';
	font-size: 13px;
}
.email {
	color: #294d8f;
	font-family: 'Robotomedium';
	font-size: 14px;
}
.emailaddrs {
	color: #5a5a5a;
	font-family: 'Robotomedium';
	font-size: 12px;
	padding-left: 5px;
}
.phone {
	color: #294d8f;
	font-family: 'Robotomedium';
	font-size: 14px;
}
.phonenum {
	color: #5a5a5a;
	font-family: 'Robotomedium';
	font-size: 12px;
	padding-left: 5px;
}
.sign {
	color: #294d8f;
	font-family: 'Robotomedium';
	font-size: 18px;
}
.signature {
	background-color: #294d8f;
	color: #f5f5f5;
	border: #294d8f 2px solid;
	padding: 8px 15px;
	font-size: 13px;
	border-radius: 10px;
	font-weight: 300;
	line-height: 1.5;
	margin: 2px;
	position: relative;
	display: inline-block;
	text-align: center;
	min-width: 290px;
	font-family: 'Robotomedium';
}
input[type='file'] {
	display: none;
}
.wraper img {
	width: 14px;
	margin-right: 10px;
}
.bottomtxt {
	color: #5a5a5a;
	font-family: 'Roboto';
	font-size: 12px;
	margin-top: 10px;
}
