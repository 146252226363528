.search {
	border-left: 0px solid #ced4da;
	border-top: 1px solid #ced4da;
	border-bottom: 1px solid #ced4da;
	border-right: 1px solid #ced4da;
	padding: 0.375rem 0.75rem;
	border-top-right-radius: 0.375rem;
	border-bottom-right-radius: 0.375rem;
	width: 368px;
}
.search:focus {
	outline: none;
}

.notifiOne,
.notifiTwo,
.notifiThree {
	position: absolute;
	top: 14px;
	background: #a0a0a5;
	padding: 5px 0 0 0;
	border-radius: 30px;
	width: 26px;
	height: 26px;
	color: #fff;
	font-weight: 600;
	text-align: center;
	font-size: 12px;
}
.notifiOne {
	left: 30%;
}
.notifiTwo {
	left: 62%;
}
.notifiThree {
	right: 35px;
}
.searchIcon img {
	height: 18px;
}
.searchIcon {
	background: #fff;
}
.searchBox {
	border: 3px solid #ced4da;
	border-radius: 8px;
}

.tabsContainer:first-of-type > li {
	border-top: 5px solid #b8b8b8;
	border-left: 5px solid #b8b8b8;
	border-right: 5px solid #b8b8b8;
	border-left: none;
	border-top-right-radius: 25px;
	border-top-left-radius: 25px;
	padding-bottom: 0px;
}
.tabsContainer {
	border-top-right-radius: 15px;
	border-top-left-radius: 15px;
	border-right: 5px solid #b8b8b8;
	padding-bottom: 0px;
}

.tabsContainer:last-of-type > li {
	border-top-right-radius: 25px;
	border-top-left-radius: 25px;
	border-top: 5px solid #b8b8b8;
	border-left: 5px solid #b8b8b8;

	border-right: none;
	padding-bottom: 0px;
}
.numberOfCompanies {
	border-radius: 50%;
	background-color: #6d6d6d;
	color: white;
	padding: 3px 7px;
	position: absolute;

	right: 25px;
	bottom: 6px;
	font-size: 13px;
}

.tab {
	border-radius: 15px 15px 0 0;
	overflow: hidden;
	padding: 0;
	width: 100%;
}

.tab button {
	background-color: #f2f2f2;
	color: #333;
	border: none;
	outline: none;
	cursor: pointer;

	flex: 1;
	border-bottom: 1px solid #ccc;
	border-right: 1px solid #ccc;
	border-left: none;
}

.tab button:hover {
	background-color: #ddd;
}

.tab button.active {
	background-color: #fff;
	border-bottom: none;
}

.tablinks {
	border-radius: 20px 20px 0px 0px;
	display: inline-block;
	width: 33.3%;

	box-sizing: border-box;

	text-align: center;
	font-weight: bold;
	cursor: pointer;
	position: relative;
}
.tabsContainer {
	padding-right: 0;
	padding-left: 0;
	border: 5px solid #b8b8b8;
	border-top: 1px solid #b8b8b8;
	border-bottom: 1px solid #b8b8b8;
	padding-bottom: 0px;
}
.addnewSTE {
	padding: 8px 100px 8px 25px !important;
	border: 3px solid #294d8f;
}
