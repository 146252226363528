.databaseWrap h2 {
	font-size: 24px;
	font-weight: 600;
	padding: 0;
	margin: 0;
	color: #294d8f;
}
.databaseWrap h3 {
	font-size: 18px;
	font-weight: 600;
	color: #666;
	margin-bottom: 20px;
}
.databaseWrap p {
	padding: 0;
	margin: 0;
	color: #666;
}

.iarTable input,
.iarTable textarea {
	border: 1px solid #ced4da;
}

.steRow span {
	width: 24px;
	height: 24px;
	text-align: center;
	margin: 0;
	padding-top: 3px;
	font-weight: 600;
	color: #fff;
	display: inline-block;
	background-color: #a4a4a4;
	border-radius: 50px;
	font-size: 12px;
}

.iarTable tr td {
	width: 11.1%;
}

.iarTable {
	overflow: hidden;
	margin-bottom: 0px;
}

.viewDetails {
	color: #4bb2d7;
	text-decoration: underline;
	border: 0;
	outline: none;
}

.iarTable th {
	background-color: #fffcf4;
	padding: 0.8rem 0.5rem;
	color: #294d8f;
}

.iarTable td table tr th {
	background-color: #c4ebeb;

	color: #294d8f;
}

.iarTable td table tr td {
	padding: 1rem;
	width: auto;
}

.search {
	border-left: 0px solid #ced4da;
	border-top: 1px solid #ced4da;
	border-bottom: 1px solid #ced4da;
	border-right: 1px solid #ced4da;
	padding: 0.375rem 0.75rem;
	border-top-right-radius: 0.375rem;
	border-bottom-right-radius: 0.375rem;
	width: 368px;
}

.searchBox:focus {
	border: 3px solid gray;
	border-radius: 5px;
}

.search:focus {
	outline: none;
}

.searchBox {
	border: 3px solid #ced4da;
	border-radius: 8px;
}

.searchIcon img {
	height: 18px;
}

.searchIcon {
	background: #fff;
}

.tableWrapper {
	padding-left: 0px;
	padding-right: 0px;
	border-radius: 10px;
}

.th {
	text-align: center;
	border: none !important;
	border-style: none !important;
}

.td {
	border: none !important;
	border-style: none !important;
	text-align: center;
	background-color: none;
	padding: 15px 0px !important;
	font-size: 16px;
}

.steRow {
	border: none !important;
	border-style: none !important;
	text-align: center;
	background-color: none;
	padding: 20px 0px !important;
}

.tr {
	border: none !important;
	border-style: none !important;
}

.addnewSTE {
	padding: 8px 100px 8px 25px;
}

.detailsTable {
	padding: 0px 20px 0px 20px;
}

.trOdd {
	border: 1.5px solid #5a5a5a;
	border-style: none !important;
	padding: 15px 0px;

	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 22px 46px #00000029;
	border: 2px solid #5a5a5a;
	border-radius: 0px 0px 10px 10px;
	opacity: 0.8;
	backdrop-filter: blur(143px);
	-webkit-backdrop-filter: blur(43px);
}

.trEven {
	border-style: none !important;
	padding: 15px 0px;
	border: 1.5px solid #5a5a5a;
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 2px 6px #00000029;
	border: 1px solid #5a5a5a;
	border-radius: 0px 0px 10px 10px;
	opacity: 0.7;

	border-bottom: 0px solid black;
}

.addnewSTE {
	padding: 8px 10px 8px 25px;
}
