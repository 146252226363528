.toggle-btn {
	position: relative;
	display: inline-block;
	width: 130px;
	height: 35px;

	border-radius: 10px;
	background-color: #fffcf4;
	cursor: pointer;
	overflow: hidden;
	border: 2px solid #ffde9b;
	padding-bottom: 10px;
}

.toggle-btn.toggled {
	background-color: #294d8f;
}

.toggle-ball {
	position: absolute;
	top: 2px;
	left: 2px;
	width: 20px;
	height: 27px;
	border-radius: 30%;
	background-color: #327072;
	transition: all 0.3s ease-in-out;
}

.toggle-btn.toggled .toggle-ball {
	left: calc(100% - 24px);
	background-color: white;
}

.Master_btn {
	position: relative;
	display: inline-block;
	width: 130px;
	height: 35px;

	border-radius: 10px;
	background-color: #294d8f;
	cursor: pointer;
	overflow: hidden;
	border: 2px solid #ffde9b;
	padding-bottom: 10px;
	vertical-align: center;
	text-align: center;
	padding: 4px 0px 0px 0px;
	font-size: 16px;
	color: white;
}
