.custom_container_table {
	max-width: 603px;
	width: 100%;
	margin: 0px 0px 0px 100px;
}

.SCP_Section table {
	width: 100%;

	border-spacing: '0';
}

.SCP_Section .table_header {
	color: #294d8f;
	font-size: 15px;
}

.SCP_Section .teble_head {
	background-color: #294d8f;
	color: aliceblue;
}

.hr_line {
	height: 1px;
	width: 180px;
	border-width: 0;
	color: black;
	background-color: black;
	margin-top: 60px;
}

.footer_points {
	font-size: 8px;
	line-height: 13px;
	list-style-type: none;
}

.footer_images_table_3 {
	margin-left: 0px;
	margin-top: 22px;
	display: flex;
	align-items: self-end;
}

.footer_images_table {
	margin-left: 5px;
	margin-top: 5px;
}

.footer_images_text {
	font-size: 9px;
	color: #294d8f;
}

.note_txt {
	font-family: 'Arial';
	font-weight: 600;
	color: black;
	font-size: 13px;
}

.note_desc {
	font-size: 13px;
	font-family: 'Arial';
	color: black;
}

.sign_box {
	display: flex;
	justify-content: space-between;
	margin-top: 55px;
}

.submitd_box {
	width: 220px;
	border: 1px solid black;
	padding: 10px 10px;
}

.sign_line {
	padding-top: 5px;
	color: black;
	opacity: 1;
}

.submitdby_txt {
	line-height: 2px;
	font-family: 'Arial';
	font-size: 13px;
	padding-bottom: 5px;
	color: black;
}

.teconslt_txt {
	font-size: 11px;
	color: black;
	font-family: 'Arial';
}

.ste_name {
	font-size: 11px;
	font-family: 'Arial';
	text-transform: capitalize;
	font-weight: 600;
	color: black;
}

.ste_txt {
	font-size: 11px;
	font-family: 'Arial';
	line-height: 1px;
	color: black;
}

.footer_images_table_5 {
	margin-left: 0px;
	margin-top: 204px;
	display: flex;
	align-items: self-end;
}

.note_text_header {
	margin-top: 25px;
	padding: 10px;
}

.custom_container_table td,
.custom_container_table tr {
	text-align: center;
}

.imgtxtSectn {
	position: relative;
}

.switchImgTxt p {
	font-family: 'Arial';
	font-size: 12px;
	color: 000;
	color: black;
	position: absolute;
	top: 9px;
	left: 278px;
}

.image_two img {
	max-width: 100%;
	width: 250px;
	margin-left: 257px;
}
