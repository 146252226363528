.custom_container_gallery {
	max-width: 630px;
	width: 100%;
	margin: 0px 0px 0px 150px;
}

.hr_line {
	height: 1px;
	width: 180px;
	border-width: 0;
	color: black;
	background-color: black;
	margin-top: 60px;
}

.footer_points {
	font-size: 8px;
	line-height: 13px;
	list-style-type: none;
}

.footer_images_table_2 {
	margin-left: 0px;
	margin-top: 67px;
	display: flex;
	align-items: self-end;
}

.footer_images_table {
	margin-left: 5px;
	margin-top: 5px;
}

.footer_images_text {
	font-size: 6px;
	color: #294d8f;
}

.gallery_img_div {
	display: flex;
	justify-content: space-around;
}

.gallery_img_div img {
	width: 500px;
	height: 250px;
	object-fit: contain;
}

.custom_container_pageNine {
	max-width: 758px;
	width: 100%;
	margin: 0px 0px 0px 110px;
	display: flex;
	flex-wrap: wrap;
	justify-content: left;
}

.img_txt {
	font-size: 12px;
	color: #294d8f;
	font-family: 'Roboto';
}

.footer_images_gallerySection {
	margin-left: 0px;
	margin-top: 5px;
	display: flex;
	align-items: self-end;
}

.imgdiv img {
	display: flex;
}

.gallery_img_div {
	width: 37%;
	border: 1px solid black;
}

.imgCaption {
	color: #294d8f;
	font-size: 15px;
	font-family: 'Arial';
	width: 375px;
	text-align: center;
}
