ul.checkpointList {
	margin: 0;
	padding: 0;
	list-style-type: none;
}
ul.checkpointList li {
	margin: 14px 0;
	color: #294d8f;
	font-weight: 600;
	padding: 15px;
	background: #fff;
	display: flex;
	align-items: center;
	position: relative;
}
ul.checkpointList li.selected {
	background: #ffffff;
	box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 30%);
	padding: 0px;
	display: flex;
	align-items: center;
	padding: 0px 16px;
	border-radius: 8px;
	cursor: pointer;
}
ul.checkpointList li.selectedNobg {
	background: #fff;
	padding: 0px;
	display: flex;
	align-items: center;
	padding: 0px 16px;
	margin: 10px 0;
	margin-bottom: 26px;
}
ul.checkpointList li.selectedNobgDeactive {
	background: #fff;
	padding: 0px;
	display: flex;
	align-items: center;
	padding: 0px 16px;
	margin: 28px 0;
	margin-bottom: 12px;
	color: #000;
}
.selectedNobgDeactive a {
	color: #8b8b8b;
}
ul.checkpointList li a {
	display: block;
	padding: 15px;
}
.warningMsg {
	background-color: #fffcf4;
	text-align: left;
	padding: 24px;
	border-radius: 15px;
}
.warningMsg h5 {
	color: #ff0000;
	font-weight: 600;
	font-size: 20px;
	margin-bottom: 15px;
}
.warningMsg p {
	color: #294d8f;
	font-weight: 500;
	margin: 0;
}
.disabledStatus a {
	color: #8b8b8b;
	cursor: not-allowed;
	pointer-events: all !important;
}
.iarVisitConformcheck {
	position: absolute;
	left: 90px;
	margin: 6px 0px 0px 0px;
	width: 24px;
	height: 24px;
	top: 12px;
}
.iarVisitConformcheck input[type='checkbox'] {
	width: 20px;
	height: 30px;
	margin: auto;
	display: table-row;
	border: 5px solid red;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
	margin-left: 4px;
	margin-top: 1px;
}
.textPosition {
	position: absolute;
	left: 65px;
	top: 20px;
}
.textFullClick {
	padding: 14px 0px !important;
	width: 100%;
}
.textPositionIAr {
	position: absolute;
	left: 65px;
	top: 34px;
}
