input.rowMeterial {
	background-color: transparent;
	border: 0;
	padding: 2px 5px;
	margin: 0;
	font-weight: 600;
	color: #294d8f;
	width: 250px;
	font-size: 14px;
	border-bottom: 1px solid;
}
input.rowMeterial::placeholder {
	color: #294d8f;
	font-weight: 600;
	font-size: 13px;
	opacity: 1;
}
input.rowMeterial:focus-visible,
input.rowMeterial:focus {
	outline: none;
	color: #707070;
	text-decoration: none;
}

.textEdit {
	background-color: transparent;
	border: 0;
	margin: 0;
	padding: 0;
}

.recordedObservations {
	background: rgb(246 246 241 / 47%);
	padding: 15px 5px;
	box-shadow: inset 0px 0px 10px 0px rgb(0 0 0 / 16%);
}

.listObservations {
	padding: 15px 20px;
	border-radius: 15px;
	background-color: #fff;
	box-shadow: 0px 0px 5px 1px rgb(0 0 0 / 5%);
	margin: 15px 0;
}

.marked {
	width: 10px;
	height: 10px;
	border-radius: 50px;
	background-color: #294d8f;
	float: right;
	margin-top: 6px;
}

.listObservations h4 {
	font-size: 13px;
	margin-bottom: 3px;
	font-weight: 700;
	color: #5a5a5a;
}

.listObservations p {
	font-size: 13px;
	margin-bottom: 0px;
	padding-right: 24px;
	line-height: 20px;
}

h3.title {
	font-size: 14px;
	font-weight: 500;
	color: #294d8f;
}

.contentObservations {
	position: relative;
}

img.remove {
	position: absolute;
	right: 0;
	bottom: 0px;
	display: inline-block;
	height: 18px;
}
.addnewcomp {
	margin: 15px 5px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.cationPlaceholder::placeholder {
	color: #000;
	opacity: 0.5;
	font-weight: 500;
	font-size: 10px;
}
.arrowProcess svg {
	font-size: 30px;
	color: #294d8f;
}
.arrowProcess {
	margin-top: 18px;
}
.processFlowHeight {
	overflow-y: auto;
}
.obsHeight {
	overflow-y: auto;
}

.obsHeight::-webkit-scrollbar,
.processFlowHeight::-webkit-scrollbar {
	width: 5px !important;
	border-radius: 20px;
}

.obsHeight::-webkit-scrollbar-track,
.processFlowHeight::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px #b2acac;
	border-radius: 10px;
}

.obsHeight::-webkit-scrollbar-thumb,
.processFlowHeight::-webkit-scrollbar-thumb {
	background: #294d8f;
	border-radius: 10px !important;
}
@media only screen and (max-width: 420px) {
	.SpantagWidth:nth-child(3) span {
		width: 80px;
		display: block;
	}
}
tr:nth-last-child(1) td .arrowProcess {
	display: none;
}
