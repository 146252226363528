@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: url('./assets/fonts/Roboto-Light.ttf');
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url('./assets/fonts/Roboto-Regular.ttf');
}

@font-face {
	font-family: 'Robotomedium';
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url('./assets/fonts/Roboto-Medium.ttf');
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url('./assets/fonts/Roboto-Bold.ttf');
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: url('./assets/fonts/Roboto-Black.ttf');
}

body {
	font-family: 'Roboto', sans-serif;
	font-size: 14px;
	font-weight: 400;
	color: #6c6c6c;
	position: relative;
}

a {
	color: #294d8f;
	text-decoration: none;
}

a:hover {
	color: #5a5a5a;
	text-decoration: none;
}

.table {
	border-radius: 1rem 1rem 0px 0px;
}

tr:nth-child(even) td {
	border-bottom: 0;
}

.reset_btn {
	background-color: #ffffff !important;
	color: #888888;
	border: #fff 1px solid;
	padding: 4px 10px;
	font-size: 11px;
	border-radius: 6px;
	font-weight: 500;
	line-height: 1.5;
	margin: 2px;
	position: relative;
	display: inline-block;
	text-align: center;
	min-width: 80px;
}

.primary_btn {
	background-color: #294d8f !important;
	color: #ffffff;
	border: #294d8f 1px solid;
	padding: 8px 15px;
	font-size: 14px;
	border-radius: 6px;
	font-weight: 500;
	line-height: 1.5;
	margin: 2px;
	position: relative;
	display: inline-block;
	text-align: center;
	min-width: 80px;
}

.secondary_btn {
	background-color: #ffffff;
	color: #294d8f !important;
	border: #294d8f 2px solid !important;
	padding: 8px 55px;
	font-size: 14px;
	border-radius: 6px;
	font-weight: 500;
	line-height: 1.5;
	margin: 2px;
	position: relative;
	display: inline-block;
	text-align: center;
	min-width: 80px;
	margin-right: 15px;
}

.success_btn {
	background-color: #e4fffe;
	color: #327072;
	border: #294d8f 1px solid;
	padding: 8px 15px;
	font-size: 14px;
	border-radius: 6px;
	font-weight: 500;
	line-height: 1.5;
	margin: 2px;
	position: relative;
	display: inline-block;
	text-align: center;
	min-width: 80px;
}

.danger_btn {
	background-color: #dc3545;
	color: #ffffff;
	border: #dc3545 1px solid;
	padding: 8px 15px;
	font-size: 14px;
	border-radius: 6px;
	font-weight: 500;
	line-height: 1.5;
	margin: 2px;
	position: relative;
	display: inline-block;
	text-align: center;
	min-width: 80px;
}

.secondary_btn:hover {
	color: white !important;
	background-color: #294d8f !important;
}

.primary_btn:hover {
	color: #fff;
}

.small_btn {
	padding: 2px 10px !important;
	font-size: 12px;
	min-width: 60px;
}

.disabled,
.disabled:hover {
	opacity: 0.7;
	background-color: #777777 !important;
	border: #777777;
	color: #000;
}

.primary_btn img,
.secondary_btn img,
.success_btn img {
	width: 12px;
	margin-right: 5px;
}

.dow_up .secondary_btn img,
.dow_up .primary_btn img {
	width: 16px;
}

.delete_btn {
	background-color: transparent;
	color: #ffffff;
	border: 0px;
	padding: 4px;
	font-size: 14px;
	border-radius: 0;
	margin: 2px;
	position: relative;
	display: inline-block;
	text-align: center;
}

.form-control {
	border-radius: 0;
	font-size: 14px;
	border-left: 0;
	border-bottom: 1px solid #ced4da;
	border-top: 0;
	border-right: 0;
	padding-left: 0;
	padding-right: 0;
	outline: none;
}

.nameSTE label {
	display: block;
}

.nameSTE .form-control {
	border: 1px solid #ced4da;
	padding: 0.375rem 0.75rem;
	width: 80%;
	float: left;
}

.form-control:focus {
	box-shadow: none;
}

.star {
	color: #dc3545;
	margin-left: 2px;
}

.table-hover > tbody > tr:hover > * {
	background-color: #ffffff;
}

.form-select {
	border-top: 0;
	border-left: 0;
	border-right: 0;
	border-radius: 0;
	font-size: 14px;
}

.autogenerate label {
	font-weight: 400;
	color: #6c6c6c;
	font-size: 13px;
}

.header {
	background: #fff;
	box-shadow: 0px 0px 10px -5px rgb(0 0 0 / 30%);
	padding: 10px 0;
}

.header-left-sec ul li {
	padding: 0px;
}

.header-right-sec ul li {
	padding: 0px;
	margin: 0;
}

img.logo {
	height: 50px;
}

.loader {
	border: 4px solid #f3f3f3;
	border-top: 4px solid #294d8f;
	border-radius: 50%;
	width: 70px;
	height: 70px;
	animation: spin 2s linear infinite;
	margin: 30px auto;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.tools {
	color: #8b8b8b;
	font-weight: 500;
	text-decoration: none;
	font-size: 16px;
}

.footer {
	background-color: #f5f5f5;
	padding: 15px 0;
}

.footer p {
	text-align: center;
	font-size: 14px;
	margin: 0;
	padding: 0;
	color: #878787;
}

.deskWrapper {
	padding: 30px 0;
	min-height: 640px;
}

ol.breadcrumb {
	margin: 10px 0 0 0;
}

.mainTitle {
	color: #294d8f;
	font-size: 24px;
	font-weight: 600;
	margin: 10px 0 0 0;
}

.breadcrumb_right_info h3 {
	color: #4e4e4e;
	font-size: 24px;
	font-weight: 600;
}

.breadcrumb_right_info h3 span {
	text-transform: uppercase;
	color: #4e4e4e;
	font-size: 14px;
	font-weight: 500;
	display: block;
}

ul.dropdown_list_admin {
	margin: 10px 0 0 0;
	padding: 20px;
	list-style-type: none;
	position: absolute;
	background: #fff;
	box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 20%);
	z-index: 9;
	border-radius: 0px 0px 20px 20px;
	width: 166px;

	left: 900px;
	right: 0px;
}

ul.dropdown_list_database {
	margin: 10px 0 0 0;
	padding: 20px;
	list-style-type: none;
	position: absolute;
	background: #fff;
	box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 20%);
	z-index: 9;
	border-radius: 0px 0px 20px 20px;
	width: 166px;

	left: 920px;
	right: 0px;
}

ul.dropdown_list li {
	margin: 0;
	border-bottom: 1px solid #e3e3e3;
}

ul.dropdown_list li:last-child {
	border-bottom: 0;
}

ul.dropdown_list li:first-child a {
	padding-top: 0;
}

ul.dropdown_list_admin li a,
ul.dropdown_list_database li a {
	color: #8b8b8b;
	text-decoration: none;
	font-size: 14px;
	font-weight: 400;
	padding: 10px 0;
	display: block;
}

.help {
	color: #8b8b8b;
	text-decoration: none;
	font-size: 16px;
	font-weight: 600;
	padding: 0 10px;
}
.help:hover {
	color: #294d8f;
}

.tools-sub-title {
	color: #8b8b8b;
	font-size: 12px;
	font-weight: 400;
	margin: 0;
}

.tab-content-admin {
	border-bottom: 1px solid #294d8f;
	border-bottom-left-radius: 8px;
	border-left: 1px solid #294d8f;
	border-right: 1px solid #294d8f;
	border-bottom-right-radius: 8px;
	min-height: 400px;
}

.nav-tabs {
	border-bottom: 1px solid #294d8f;
}

li.nav-item button {
	color: #8f8f8f;
	font-weight: bold !important;
	font-size: 14px;
	padding: 15px 10px;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link,
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link {
	border: 1px solid #e6e6e6;
	color: #6d6d6d;
	background-color: #fffcf4;
	font-size: 14px;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active,
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
	border-top: 1px solid #00aa45;
	border-left: 1px solid #00aa45;
	border-right: 1px solid #00aa45;
	border-bottom: 1px solid #00aa45;
	color: #00aa45;
	background-color: #d5ffe6;
	font-size: 14px;
}

.workspace.active {
	color: #294d8f;
}

.form_title {
	font-size: 20px;
	color: #327072;
	font-weight: 500;
}

.acceptterms label {
	font-size: 14px;
	font-weight: 400;
	color: #4e4e4e;
}

.acceptterms label input[type='checkbox'] {
	margin-right: 8px;
}

.footer_mobile {
	background-color: #fffcf4;
	position: fixed;
	bottom: 0;
	width: 100%;
	padding: 10px 15px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: top center;
	z-index: 1;
	transition: 0.3s;
}

.text-end {
	transition: 0.3s;
}

.footerBtn img {
	height: 45px;
}

.footerBtn a {
	font-weight: 600;
	font-size: 16px;
	color: #294d8f;
}

img.LoginLogo {
	min-height: 150px;
	display: inline-block;
}

.selectLang .btn-primary {
	display: inline-block;
	padding: 1.5em 2.5em;
	font-size: 16px;
	cursor: pointer;
	font-weight: 600;
	color: #294d8f;
	border: 2px solid #294d8f;
	border-radius: 0.25em;
	background: #fff;
	box-shadow: none;
	transition: 0.3s;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.selectLang .btn-check:checked + .btn,
.selectLang .btn.active,
.btn.show,
.selectLang .btn:first-child:active,
:not(.btn-check) + .btn:active {
	background: #294d8f;
	color: #fff;
	border: 2px solid #294d8f;
}

.selectLang .btn-group > :not(.btn-check:first-child) + .btn {
	margin-left: 10px;
}

.selectLang .btn-group > .btn-group:not(:first-child) > .btn,
.selectLang .btn-group > .btn:nth-child(n + 3),
.selectLang .btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
	border-top-left-radius: 0.25em;
	border-bottom-left-radius: 0.25em;
	border-top-right-radius: 0.25em;
	border-bottom-right-radius: 0.25em;
}

.login_privacy p {
	font-size: 13px;
}

.LoginLogo {
	max-width: 100%;
}

.companiesTab {
	position: relative;
}

.iarSubtable {
	background-color: #fffcf4;
	border: 1px solid #294d8f;
	font-size: 13px;
}

.iarSubtable span {
	font-weight: 600;
}

.tclist .modal-content {
	padding: 20px;
	border: 3px solid #239a9c;
}

.tclist h2 {
	color: #294d8f;
	font-size: 18px;
	font-weight: 600;
}

.tclist .modal-header {
	border: 0;
}

.deactivateCompany h4 {
	margin: 0 0 20px 0;
	font-size: 18px;
	color: #294d8f;
	font-weight: 600;
	text-align: left;
}

.deactivateCompany .modal-header {
	border: 0;
	padding-bottom: 0;
}

.deactivateCompany .modal-body {
	padding-bottom: 20px;
}

.deactivateCompany .modal-content {
	border: 4px solid #ffde9b;
	background: #fffcf4;
}

.assignedSTE select.form-select {
	border: 1px solid #294d8f;
	color: #294d8f;
	font-size: 12px;
	font-weight: 500;
	min-width: 140px;
}

.form-select:focus {
	box-shadow: none;
}

@media only screen and (min-width: 769px) {
	.footer_mobile {
		display: none;
	}
}

@media only screen and (max-width: 768px) {
	.list-inline {
		display: flex;
		list-style: none;
		align-items: center;
	}

	.list-inline li:nth-child(2) {
		margin-left: auto;
	}

	.header {
		background: white;
		background-color: white;
	}

	.hambergerMenu {
		position: relative;
	}

	.hambergerMenu span {
		width: 34px;
		height: 3px;
		background-color: #327072;
		display: block;
		margin: 7px 0px;
	}

	.header-left-sec ul,
	.header-right-sec ul {
		list-style: none;
	}

	.header {
		padding: 6px 6px;
		position: relative;
	}

	img.logo {
		object-fit: contain;
		max-width: 100%;
		padding: 5px;
	}

	.notifiRedicon {
		position: absolute;
		top: -4px;
		left: -8px;
		width: 10px;
		height: 10px;
		background-color: #ff0000;
		border-radius: 50%;
	}

	.mobileMenu {
		background-color: #fffcf4;
		width: 276px;
		position: fixed;
		right: -100%;
		top: 0;
		bottom: 0;
		z-index: 2;
		transition: 0.3s;
		border-radius: 10px 0px 0px 10px;
		border: 2px solid #fff6d9;
	}

	.mobileMenuActive {
		right: 0%;
		transition: 0.3s;
		border-radius: 10px 0px 0px 10px;
		border: 2px solid #fff6d9;
	}

	.menuOpenwrapper {
		padding: 18px 24px;
	}

	.menuOpenwrapper {
		display: flex;
		align-items: center;
		width: 238px;
		margin: auto;
	}

	.hambergerMenu {
		color: #294d8f;
	}

	.menuOpenwrapper h2 {
		font-size: 28px;
		color: #294d8f;
		font-weight: 500;
		margin-bottom: 0;
		margin-left: 36px;
	}

	.mobileMenu-cat li a {
		font-size: 15px;
		color: #5a5a5a;
		font-family: Robotomedium;
	}

	.mobileMenu-cat li {
		border-bottom: 1px solid #e1e1e1;
		padding: 22px 24px !important;
	}

	.mobileMenu-cat {
		width: 238px;
		margin: auto !important;
	}

	.notif-label {
		background-color: #4e4e4e;
		border-radius: 50%;
		float: right;
		color: #fff;
		font-size: 10px;
		padding: 0px 6px;
		display: flex;
		align-items: center;
		height: 22px;
		justify-content: center;
	}

	.mobileMenu-cat li ul li {
		border-bottom: 0px solid #e1e1e1;
		padding: 10px 0px !important;
	}

	.mobLogout {
		width: 238px;
		margin: auto !important;
		padding: 0px 20px !important;
	}

	.mobLogPadding {
		padding: 20px 5px !important;
		font-size: 17px;
		color: #294d8f;
		font-family: 'Robotomedium';
	}

	.mobLogout ul {
		padding-bottom: 10px !important;
		position: absolute;
		bottom: 0;
	}

	.footer {
		display: none;
	}

	.deskWrapper {
		padding: 30px 0 70px 0;
	}

	.toolsBox {
		position: fixed;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		background-color: #ffffff;
	}

	.text-end {
		transition: 0.3s;
	}

	.tooltextTransiActive {
		margin-right: 25px;
		margin-left: 10px;
		transition: 0.3s;
		opacity: 1;
	}

	.tooltextTransi {
		margin-left: 10px;
		transition: 0.3s;
		opacity: 0;
	}

	.tooltextTransiActiveleft {
		margin-right: 0px;
		margin-left: 10px;
		transition: 0.3s;
		opacity: 1;
	}

	img.LoginLogo {
		margin-bottom: 80px;
		height: 150px;
	}
}

@media only screen and (max-width: 320px) {
	.mobileMenu-cat li {
		padding: 12px 24px !important;
	}

	.mobileMenu-cat li ul li {
		padding: 6px 0px !important;
	}

	.hambergerMenu span {
		width: 28px;
		height: 3px;
		background-color: #327072;
		display: block;
		margin: 6px 0px;
	}

	.menuOpenwrapper h2 {
		font-size: 26px;
	}

	.footerBtn a {
		display: flex;
		align-items: center;
		justify-content: end;
	}

	.footerBtn img {
		height: 34px;
	}
}
