.phone-input {
	position: relative;
}

.phone-code {
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	padding: 0.5rem;
	display: flex;
	align-items: center;
	color: #999;
	pointer-events: none;
}

.phone-input input[type='tel'] {
	padding-left: 3.5rem;

	width: 100%;
}
