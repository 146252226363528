.iarTable input,
.iarTable textarea {
	border: 1px solid #ced4da;
}
.iarTable th {
	color: #294d8f;
}
.moreMenu {
	position: relative;
}
.Observations textarea {
	height: 120px;
}
.iarTablecurv {
	border: 2px solid #8b8b8b;
	border-radius: 1rem;
	max-width: 900px;
	display: inline-block;
	border-radius: 1em;
	overflow: hidden;
	margin-top: 30px;
}
.saveButton img {
	width: 20px;
}
.saveButton {
	padding: 0;
	outline: none;
	border: none;
	position: absolute;
	right: 0;
	top: 8px;
}
.marked {
	width: 10px;
	height: 10px;
	border-radius: 50px;
	background-color: #294d8f;
	margin-top: 12px;
	position: absolute;
	left: 10px;
}
.iarTable tr td {
	position: relative;
}
.iarTable tr td:nth-child(1) input {
	width: 100%;
	margin: auto;
}
.iarTable tr:nth-child(even) td {
	padding: 8px;
}
.arrowProcess svg {
	font-size: 36px;
	color: #327072;
}

.arrowProcess {
	margin-top: 30px;
}
.imageOnsite img {
	width: 400px;
	height: 200px;
	object-fit: contain;
}
.imageOnsite:nth-child(1) {
	border-right: 1px solid #000;
}
tr:nth-last-child(1) td .arrowProcess {
	display: none;
}
