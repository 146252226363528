.tableWrapper {
	padding-left: 0px;
	padding-right: 0px;
	border-radius: 10px;
}

.iarTable input,
.iarTable textarea {
	border: 1px solid #ced4da;
}
.steRow p {
	margin: 0;
	border: none !important;
	border-style: none !important;
	text-align: center;
}
.iarTable {
	overflow: hidden;
	margin-bottom: 0px;
}

.iarTable tr td {
	width: 11.1%;
}

.viewDetails {
	color: #4bb2d7;
	text-decoration: underline;
	border: 0;
	outline: none;
	background-color: inherit;
	font-weight: bold;
	background-color: inherit;
}
.iarTable th {
	background-color: #fffcf4;
	padding: 1.25rem 0.5rem;
	color: #294d8f;
}
.iarTable td table tr th {
	background-color: #c4ebeb;
	padding: 0.6rem 0.5rem;
	color: #294d8f;
}
.iarTable td table tr td {
	padding: 0.5rem;
	width: 20%;
}
.three_btns {
	background-color: #ffffff;
	color: #294d8f;
	border: #294d8f 2px solid;
	padding: 2px 15px;
	font-size: 14px;
	border-radius: 6px;
	font-weight: 500;
	line-height: 1.5;
	margin: 3px;
	position: relative;
	display: inline-block;
	text-align: center;
	min-width: 90px;
}
.subTable {
	border: 4px solid #327072;
	border-radius: 15px;
	border-top: 0px;
	border-top-right-radius: 0px;
	border-top-left-radius: 0px;
	margin: 0px 20px;
	padding: 0px 15px;
	background-color: #fffcf4;
}

.th {
	text-align: center;
	border: none !important;
	border-style: none !important;
}
.td {
	border: none !important;
	border-style: none !important;
	text-align: center;
	background-color: none;
	padding: 20px 10px !important;
	font-size: 16px;
}
.tr {
	border: none !important;
	border-style: none !important;
}
.treven {
	background-color: #ffffff !important;
	border: none !important;
	border-style: none !important;
	font-size: 16px;
}
.trodd {
	background-color: #f5f5f5 !important;
	border: none !important;
	border-style: none !important;
	font-size: 18px;
}
