.custom_container_pagethree {
	max-width: 624px;
	width: 100%;
	margin: 0px 0px 0px 90px;
}
.logoHeader {
	display: flex;
	margin-top: 25px;
	margin-left: 94px;
	align-items: center;
	margin-bottom: 10px;
	opacity: 0.6;
}
.header {
	font-size: 16px;
	color: #50ab92;
	font-family: 'Arial';
	font-weight: 600;
}

.flowdiagram_header_txt {
	font-size: 13px;
	color: black;
	font-family: 'Roboto';
	text-decoration: underline;
}

.flowdiagram_sec_one {
	display: flex;
	justify-content: space-between;
	font-size: 9px;
	font-weight: bold;
}

.flowdiagram_sec_two {
	display: flex;
	justify-content: space-between;
	font-size: 9px;
	font-weight: bold;
}

.text_article_two {
	font-size: 13px;
	text-align: justify;
	line-height: 0.6cm;
	color: black;
	font-family: 'Arial';
}

.processDiagram {
	margin: 0 0 0 -11px;
}

.gg-arrow-left {
	box-sizing: border-box;
	position: relative;
	display: block;
	transform: scale(var(--ggs, 1));
	width: 22px;
	height: 22px;
}

.gg-arrow-left::after,
.gg-arrow-left::before {
	content: '';
	display: block;
	box-sizing: border-box;
	position: absolute;
	left: 3px;
}

.gg-arrow-long-right {
	box-sizing: border-box;
	position: relative;
	top: 14px;
	display: block;
	transform: scale(var(--ggs, 1));
	border-top: 2px solid transparent;
	border-bottom: 2px solid transparent;
	box-shadow: inset 0 0 0 2px;
	width: 11px !important;
	height: 6px;
}

.gg-arrow-long-right::after {
	content: '';
	display: block;
	box-sizing: border-box;
	position: absolute;
	width: 6px;
	height: 6px;
	border-top: 2px solid;
	border-right: 2px solid;
	transform: rotate(45deg);
	right: 0;
	bottom: -2px;
}

.flowdiagram_left_txt {
	text-align: end;
	font-size: 13px;
}

.flowdiagram_right_txt {
	text-align: start;
	font-size: 13px;
}

.flowdiagram_mid_txt {
	padding: 10px;
	text-align: center;
	border: 1px solid black;
	background-color: #bac4df94;
	width: 150px;
	font-size: 13px;
}

.flowdiagram_mid_txt_two {
	padding: 10px;
	border: 1px solid black;
	background-color: #bac4df94;
}

.fig_text {
	font-size: 9px;
	color: #50ab92;
	font-style: italic;
	text-align: center;
}

.prject_implementd_three {
	margin-top: 196px;
	font-size: 8px;
	color: #50ab92;
	text-align: center;
}

.gg_arrow_down_figone {
	box-sizing: border-box;
	position: relative;
	display: block;
	transform: scale(var(--ggs, 1));
	width: 22px;
	height: 22px;
	right: 138px;
	top: 37px;
}

.gg_arrow_down_figone::after,
.gg_arrow_down_figone::before {
	content: '';
	display: block;
	box-sizing: border-box;
	position: absolute;
	bottom: 4px;
}

.gg_arrow_down_figone::after {
	width: 6px;
	height: 6px;
	border-bottom: 2px solid;
	border-left: 2px solid;
	transform: rotate(-45deg);
	left: 8px;
}

.gg_arrow_down_figone::before {
	width: 2px;
	height: 11px;
	left: 10px;
	background: currentColor;
}

.processflowRow {
	display: flex;
	flex-wrap: wrap;
}

.processflowCol {
	flex: 1 0 18%;

	margin: 5px;
	background: tomato;
	height: 50px;
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
}

.midcol {
	background-color: gray;
}

.flowdiagram_header_preview {
	display: flex;
	justify-content: space-between;
	font-size: 13px;
	font-weight: bold;
	font-family: 'Arial';
	margin: 0px 20px 11px 59px;
	width: 485px;
}

.processDiagram {
	margin: 0 0 0 -11px;
}

.imgtxtSectn {
	position: relative;
}

.switchImgTxt p {
	font-family: 'Arial';
	font-size: 12px;
	color: 000;
	color: black;
	position: absolute;
	top: 9px;
	left: 278px;
}

.image_two img {
	max-width: 100%;
	width: 250px;
	margin-left: 257px;
}

.flowdiagram_header_diagram_preview {
	font-size: 13px;
	font-weight: bold;
	margin: 0px 0px 0px 0px;
	width: 720px;
}

.flowdiagram_header_diagram_preview p {
	margin-bottom: 0px;
}

.fig_text_preview {
	font-size: 12px;
	color: #50ab92;
	font-family: 'ArialItalic';
	margin-top: 20px;
	text-align: center;
	width: 604px;
	margin: 0px 0px 0px 0px;
}
