@import-normalize;

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: url('./assets/fonts/Roboto-Light.ttf');
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url('./assets/fonts/Roboto-Regular.ttf');
}

@font-face {
	font-family: 'Robotomedium';
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url('./assets/fonts/Roboto-Medium.ttf');
}

@font-face {
	font-family: 'RobotoBold';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url('./assets/fonts/Roboto-Bold.ttf');
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: url('./assets/fonts/Roboto-Black.ttf');
}

@font-face {
	font-family: 'Arial';
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: url('./assets/fonts/Arial.TTF');
}

@font-face {
	font-family: 'ArialBold';
	font-style: normal;

	font-display: swap;
	src: url('./assets/fonts/Arial-Bold.ttf');
}

@font-face {
	font-family: 'ArialRegular';
	font-style: normal;

	font-display: swap;
	src: url('./assets/fonts/Arial-Medium.ttf');
}

@font-face {
	font-family: 'ArialItalic';
	font-style: normal;

	font-display: swap;
	src: url('./assets/fonts/ArialCEItalic.ttf');
}

@font-face {
	font-family: 'CalibriLight';
	font-style: normal;

	font-display: swap;
	src: url('./assets/fonts/Calibri-Light.ttf');
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;

	font-display: swap;

	src: url('./assets/fonts/Roboto-Medium.ttf');
}

@font-face {
	font-family: 'Roboto,Extrabold';
	font-style: normal;

	font-display: swap;

	src: url('./assets/fonts/Roboto-Bold.ttf');
}

div,
p,
.text {
	color: #6c6c6c;
}

label {
	font-weight: 600;
	font-size: 14px;
	color: #00aa45;
}

code {
	font-family: 'Roboto', sans-serif;
	font-weight: 600;
}

* {
	scrollbar-width: auto;
	scrollbar-color: #294d8f;
}

*::-webkit-scrollbar {
	width: 15px;
}

::-webkit-scrollbar-thumb {
	background: #294d8f;
	border-radius: 15px;
}

*::-webkit-scrollbar-track {
	background: #ffffff;
	box-shadow: inset 0 0 5px grey;
}

a {
	color: #294d8f;
	text-decoration: none;
}

a:hover {
	color: #5a5a5a;
	text-decoration: none;
}

.primary_btn {
	background-color: #294d8f;
	color: #ffffff;
	border: #294d8f 1px solid;
	padding: 8px 15px;
	font-size: 14px;
	border-radius: 6px;
	font-weight: 500;
	line-height: 1.5;
	margin: 2px;
	position: relative;
	display: inline-block;
	text-align: center;
	min-width: 80px;
}

.secondary_btn {
	background-color: #ffffff;
	color: #294d8f !important;
	border: #294d8f 2px solid !important;
	padding: 8px 65px;
	font-size: 14px;
	border-radius: 50px !important;
	font-weight: 500;
	line-height: 1.5;
	margin: 2px;
	position: relative;
	display: inline !important;
	text-align: center;
	min-width: 80px;
	font-family: 'Robotomedium';
}

.secondary_btn:hover {
	background-color: #294d8f !important;
	color: #fff !important;
}

.darkline_btn {
	background-color: #ffffff;
	color: #707070;
	border: #8b8b8b 1px solid;
	padding: 8px 15px;
	font-size: 14px;
	border-radius: 6px;
	font-weight: 500;
	line-height: 1.5;
	margin: 2px;
	position: relative;
	display: inline-block;
	text-align: center;
	min-width: 80px;
}

.blueline_btn {
	background-color: #ffffff;
	color: #707070;
	border: #6bcecc 1px solid;
	padding: 8px 15px;
	font-size: 14px;
	border-radius: 6px;
	font-weight: 500;
	line-height: 1.5;
	margin: 2px;
	position: relative;
	display: inline-block;
	text-align: center;
	min-width: 80px;
}

.deletRow_btn {
	background-color: #ffffff;
	color: #707070;
	border: #8b8b8b 1px solid;
	padding: 8px 15px;
	font-size: 14px;
	border-radius: 4px;
	font-weight: 500;
	line-height: 1.5;
	margin: 2px;
	position: relative;
	display: inline-block;
	text-align: center;
	min-width: 80px;
}

.deletRow_btn:hover {
	color: #294d8f;
	border: #294d8f 1px solid;
}

.success_btn {
	background-color: #e4fffe !important;
	color: #327072;
	border: #294d8f 3px solid !important;
	padding: 8px 15px;
	font-size: 14px;
	border-radius: 6px;
	font-weight: 500;
	line-height: 1.5;
	margin: 0px !important;
	position: relative;
	display: inline-block;
	text-align: center;
	min-width: 150px !important;
}

.danger_btn {
	background-color: #dc3545;
	color: #ffffff;
	border: #dc3545 1px solid;
	padding: 8px 15px;
	font-size: 14px;
	border-radius: 6px;
	font-weight: 500;
	line-height: 1.5;
	margin: 2px;
	position: relative;
	display: inline-block;
	text-align: center;
	min-width: 80px;
}

.secondary_btn:hover {
	color: #294d8f;
}

.primary_btn:hover {
	color: #fff;
}

.small_btn {
	padding: 6px 10px !important;
	font-size: 11px !important;
	min-width: 150px !important;
}

.primary_btn img,
.secondary_btn img,
.success_btn img,
.darkline_btn img,
.blueline_btn img {
	width: 10px;
	margin-right: 5px;
}

.deletRow_btn img {
	width: 12px;
	margin-right: 5px;
}

.delete_btn {
	background-color: transparent;
	color: #8b8b8b;
	border: 0px;
	padding: 4px;
	font-size: 14px;
	border-radius: 0;
	margin: 2px;
	position: relative;
	display: inline-block;
	text-align: center;
	z-index: 9;
}

.form-control {
	border-radius: 0;
	font-size: 14px;
	border-left: 0;
	border-bottom: 1px solid #ced4da;
	border-top: 0;
	border-right: 0;
	padding-left: 0;
	padding-right: 0;
	outline: none;
}

.form-control:focus {
	box-shadow: none;
}

.star {
	color: #dc3545;
	margin-left: 2px;
}

.table-hover > tbody > tr:hover > * {
	background-color: #ffffff;
}

.header {
	background: #fff;
	box-shadow: 0px 0px 32px -11px rgb(0 0 0 / 26%) !important;
	padding: 10px 0;
}

.header-left-sec ul,
.header-right-sec ul {
	margin: 0;
	padding: 0;
}

.header-left-sec ul li {
	padding: 0px;
	margin: 0;
}

.header-right-sec ul li {
	padding: 0px;
	margin: 0;
	position: relative;
}

.header-right-sec ul {
	margin-top: 8px;
}

img.logo {
	height: 50px;
}

.workspace {
	font-size: 18px;
	border: 0;
	background: transparent;
	outline: none;
	margin: 0 10px 0 20px;
	color: #294d8f;
	text-decoration: none;
	font-family: 'Robotomedium';
	padding: 0 10px;
}

.workspace:hover {
	color: #294d8f;
}

.tools {
	color: #8b8b8b;
	font-weight: 500;
	text-decoration: none;
	font-size: 16px;
}

.footer {
	background-color: #f5f5f5;
	padding: 15px 0;
	position: fixed;
	bottom: 0;
	right: 0;
	left: 0;
	width: 100%;
}

.footer p {
	text-align: center;
	font-size: 14px;
	margin: 0;
	padding: 0;
	color: #878787;
}

.deskWrapper {
	padding: 30px 0;
	min-height: 640px;
}

ol.breadcrumb {
	margin: 10px 0 0 0;
}

.mainTitle {
	color: #294d8f;
	font-size: 24px;
	font-weight: 600;
	margin: 20px 0 0 0;
}

.breadcrumb_right_info h3 {
	color: #294d8f;
	font-size: 24px;
	font-weight: 600;
}

.breadcrumb_right_info h3 span a {
	text-transform: capitalize !important;
	color: #4e4e4e !important;
	font-size: 12px !important;
	font-weight: 500 !important;
	display: block !important;
	font-family: 'Robotomedium' !important;
	margin-top: 3px !important;
	text-decoration: underline;
}

.dropdown_btn {
	border: 0;
	background: transparent;
	outline: none;
	margin: 0;
	color: #8b8b8b;
	text-decoration: none;
	font-size: 16px;
	font-family: 'Robotomedium';
	padding: 0 10px;
	position: relative;
}

.dropdown_btnColor {
	color: #294d8f;
}

.dropdown_btnBorderColor:before {
	border-left: 3px solid #294d8f !important;
	border-bottom: 3px solid #294d8f !important;
}

.dropdown_btn:hover {
	color: #294d8f;
}

ul.dropdown_list {
	margin: 10px 0 0 0;
	padding: 20px;
	list-style-type: none;
	position: absolute;
	background: #fff;
	box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 20%);
	z-index: 10;
	border-radius: 0px 0px 20px 20px;
	width: 166px;

	left: -8px;
	right: 0px;
}

.dropdown_list_notification {
	margin: 10px 0 0 0;
	padding: 20px;
	list-style-type: none;
	position: absolute;
	background: #fff;
	box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 20%);
	z-index: 10;
	border-radius: 0px 0px 20px 20px;
	right: -190px;
}

.dropdown_list_notification ul {
	padding: 0px 4px 0px 0px;
}

.dropdown_list_notification li ul {
	border-bottom: 1px solid 1px solid #0000004f;
}

ul.dropdown_list_tools {
	cursor: pointer;
	margin: 10px 0 0 0;
	padding: 20px;
	list-style-type: none;
	position: absolute;
	background: #fff;
	box-shadow: 0px 0px 10px -5px rgb(0 0 0 / 82%);
	z-index: 9;
	border-radius: 10px;
	max-width: 360px;
	max-height: 502px;
	overflow-x: none;
	overflow-y: scroll;
}

.header_tc_web ul.dropdown_list::-webkit-scrollbar {
	width: 5px;
}

.header_tc_web ul.dropdown_list::-webkit-scrollbar-thumb {
	background: #b8b8b8;
}

.header_tc_web ul.dropdown_list_tools::-webkit-scrollbar {
	width: 5px;
}

.header_tc_web ul.dropdown_list_tools::-webkit-scrollbar-thumb {
	background: #294d8f;
}

.header_tc_web .notificationHeight::-webkit-scrollbar {
	width: 5px !important;
	border-radius: 10px;
}

.notificationHeight::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px #f0eaea;
	border-radius: 10px;
}

.header_tc_web .notificationHeight::-webkit-scrollbar-thumb {
	background: #b8b8b8;
	border-radius: 10px !important;
}

ul.dropdown_list li {
	margin: 0;
	border-bottom: 1px solid #e3e3e3;
}

ul.dropdown_list li:last-child {
	border-bottom: 0;
}

ul.dropdown_list li:first-child a {
	padding-top: 0;
}

ul.dropdown_list li a {
	color: #8b8b8b;
	text-decoration: none;
	font-size: 14px;
	font-weight: 400;
	padding: 10px 0;
	display: block;
}

.list-inline-item:not(:last-child) {
	margin-right: 22px;
}

.arrow_up {
	position: absolute;
	top: 0%;
	left: 90%;
	width: 15px;
	height: 15px;
	transform: translate(0%, 0%);
}

.arrow_up:before {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	border-left: 3px solid #8b8b8b;
	border-bottom: 3px solid #8b8b8b;
	transform: translate(2.66667px, 1.66667px) rotate(-45deg);
	animation: arrows 1.5s linear infinite;
}

.arrow_down {
	position: absolute;
	top: 28%;
	left: 92%;
	width: 15px;
	height: 15px;
	transform: translate(0%, 0%);
}

.arrow_down:before {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	border-left: 3px solid #8b8b8b;
	border-bottom: 3px solid #8b8b8b;
	transform: translate(2.66667px, 1.66667px) rotate(136deg);
	animation: arrows 1.5s linear infinite;
}

.dropdown_btn:hover .arrow_up:before {
	border-left: 3px solid #294d8f;
	border-bottom: 3px solid #294d8f;
}

.dropdown_btn:hover .arrow_down:before {
	border-left: 3px solid #294d8f;
	border-bottom: 3px solid #294d8f;
}

ul.scp_list {
	margin: 0;
	list-style-type: none;
	position: absolute;
	background: #fffcf4;
	box-shadow: 0px 0px 10px -5px rgb(0 0 0 / 45%);
	padding: 5px 5px 5px 28px;
	top: 10px;
	left: 8px;
	width: 124px;
	z-index: 7;
	border-radius: 4px;
	border: 1px solid #ffde9b;
}

ul.scp_list li {
	margin: 0 5px;
	float: left;
}

.help {
	color: #294d8f;
	text-decoration: none;
	font-size: 16px;
	font-weight: 600;
	padding: 0 10px;
}

.tools-sub-title {
	color: #8b8b8b;
	font-size: 12px;
	font-weight: 400;
	margin: 0;
}

.tab-content {
	border-bottom: 2px solid #b8b8b8;
	border-left: 2px solid #b8b8b8;
	border-right: 2px solid #b8b8b8;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
	border-radius: 0px 0px 15px 15px;
	min-height: 400px;
	padding: 20px 10px;
}

.nav-tabs {
	border-bottom: 1px solid #294d8f;
	padding: 0;
}

li.nav-item button {
	color: #8f8f8f;
	font-weight: 600;
	font-size: 14px;
	padding: 15px 10px;
	padding: 0;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link,
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link {
	color: #294d8f;
	background-color: #fffcf4;
	font-size: 14px;
	color: #6d6d6d;
	font-weight: 500;
	border-color: #294d8f;

	padding: 0px;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active,
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
	color: #294d8f;
	background-color: #fff4d0;
	font-size: 14px;
	font-weight: 600;
	border-radius: 20px 20px 0px 0px;
	opacity: 1;
	border-color: #6d6d6d;
	padding: 15px;
}

.nav-item {
	padding: 0;
}

.form_title {
	font-size: 20px;
	color: #327072;
	font-weight: 500;
}

.acceptterms label {
	font-size: 14px;
	font-weight: 400;
	color: #4e4e4e;
	display: flex;
}

.acceptterms label input[type='checkbox'] {
	margin-right: 8px;
	width: 20px;
	height: 20px;
}

.footer_mobile {
	background-color: #fffcf4;
	position: fixed;
	bottom: 0;
	width: 100%;
	padding: 10px 15px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: top center;
	z-index: 1;
	transition: 0.3s;
}

.text-end {
	transition: 0.3s;
}

.footerBtn img {
	height: 30px;
}

.footerBtn a {
	font-weight: 600 !important;
	font-size: 13px !important;
	color: #294d8f;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.tooltextTransiActiveleft {
	font-weight: 600 !important;
	font-size: 13px !important;
	color: #294d8f;
}

img.LoginLogo {
	height: 150px;
	display: inline-block;
	margin-bottom: 80px;
}

.selectLang .btn-primary {
	display: inline-block;
	padding: 1.5em 2.5em;
	font-size: 16px;
	cursor: pointer;
	font-weight: 600;
	color: #294d8f;
	border: 2px solid #294d8f;
	border-radius: 0.25em;
	background: #fff;
	box-shadow: none;
	transition: 0.3s;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.selectLang .btn-check:checked + .btn,
.selectLang .btn.active,
.btn.show,
.selectLang .btn:first-child:active,
:not(.btn-check) + .btn:active {
	background: #294d8f;
	color: #fff;
	border: 2px solid #294d8f;
}

.selectLang .btn-group > :not(.btn-check:first-child) + .btn {
	margin-left: 10px;
}

.selectLang .btn-group > .btn-group:not(:first-child) > .btn,
.selectLang .btn-group > .btn:nth-child(n + 3),
.selectLang .btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
	border-top-left-radius: 8px !important;
	border-bottom-left-radius: 8px !important;
	border-top-right-radius: 8px !important;
	border-bottom-right-radius: 8px !important;
}

.processFlow table tr th {
	color: #327072;
	background-color: #e4fffe99 !important;
	border: 0;
}

.processFlow table tr td {
	border: 0;
	padding: 20px 0 40px 0;
}

.processFlow table tr th:nth-child(1) {
	background-image: url(./assets/images/arrow-right.png);
	background-repeat: no-repeat;
	background-size: 30px;
	background-position: center right;
}

.processFlow table tr th:nth-child(2) {
	background-image: url(./assets/images/arrow-right.png);
	background-repeat: no-repeat;
	background-size: 30px;
	background-position: center right;
}

.selectGalleryImg .btn-primary {
	display: inline-block;
	padding: 1.5em 2.5em;
	font-size: 16px;
	cursor: pointer;
	font-weight: 600;
	color: #294d8f;
	border: 3px solid #707070;
	border-radius: 0.25em;
	background: #fff;
	box-shadow: none;
	transition: 0.3s;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	width: 200px;
	padding: 5px;
	margin-bottom: 15px;
}

.selectGalleryImg .btn-check:checked + .btn,
.selectGalleryImg .btn.active,
.btn.show,
.selectGalleryImg .btn:first-child:active,
:not(.btn-check) + .btn:active {
	background: #eaeaea;
	color: #fff;
	border: 2px solid #294d8f;
}

.selectGalleryImg .btn-group > :not(.btn-check:first-child) + .btn {
	margin-left: 10px;
}

.selectGalleryImg .btn-check + .btn:hover {
	background-color: #eaeaea;
}

.selectGalleryImg .btn-group > .btn-group:not(:first-child) > .btn,
.selectGalleryImg .btn-group > .btn:nth-child(n + 3),
.selectGalleryImg .btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
	border-top-left-radius: 8px !important;
	border-bottom-left-radius: 8px !important;
	border-top-right-radius: 8px !important;
	border-bottom-right-radius: 8px !important;
}

.btn-check + .btn:hover {
	border-color: unset !important;
}

.selectGalleryImg p {
	font-weight: 400;
	font-size: 13px;
	margin: 0px 0 8px 0;
	text-align: left;
	color: #6d6d6d;
	line-height: 23px;
	padding: 0px 10px;
	width: 200px;
}

.selectGalleryImg img {
	height: 80px;
	max-width: 100%;
}

.selectGalleryImg .btn-group {
	display: block;
}

.galleryList h2 {
	color: #327072;
	font-size: 18px;
	font-weight: 600;
	margin: 0;
}

.newObservation .modal-content {
	padding: 30px;
	border: 4px solid #294d8f;
}

textarea.form-control {
	border: 1px solid #ced4da;
}

.AddNewObservation .form-select {
	border-left: 0;
	border-bottom: 1px solid #ced4da;
	border-top: 0;
	border-right: 0;
	border-radius: 0;
	padding-left: 0;
}

.form-select:focus {
	box-shadow: none;
}

.newObservation .modal-header {
	border: 0;
}

.addResources {
	max-width: 200px;
	display: inline-block;
}

.imageName {
	color: #294d8f;
	font-weight: 400;
	font-style: italic;
	font-size: 11px;
	margin-right: 5px;
}

.imageName span {
	color: #294d8f;
	font-weight: 600;
	font-style: initial;
	font-size: 12px;
	margin-right: 5px;
	cursor: pointer;
}

.customBordered td {
	text-align: center;
}

.customBordered {
	border-collapse: collapse;
	border-radius: 1em;
	overflow: hidden;
	margin: 0;
	border: 0;
}

.newObservation.removeConform .modal-header {
	padding: 0;
}

.removeConform h4 {
	font-size: 16px;
	color: #327072;
	font-weight: 600;
}

.newObservation.removeConform .modal-content {
	padding: 15px;
	border: 6px solid #ffde9b;
	background-color: #fffcf4;
}

.customBordered img,
.customBordered td,
.customBordered tr {
	border-radius: 1em;
}

.customBordered p {
	color: #5a5a5a;
	font-weight: 500;
	text-align: left;
}

.customBordered p span {
	color: #1e989a;
}

@media only screen and (min-width: 769px) {
	.footer_mobile {
		display: none;
	}
}

@media only screen and (max-width: 992px) {
	.small_btn {
		min-width: 60px;
	}

	.tabPadding button {
		padding: 6px !important;
	}

	.list-inline {
		display: flex;
		list-style: none;
		align-items: center;
	}

	.list-inline li:nth-child(2) {
		margin-left: auto;
	}

	.header {
		background: var(--unnamed-color-fffcf4) 0% 0% no-repeat padding-box;
		background-color: #fffcf4 0% 0% no-repeat padding-box;
		border: 1px solid #fff6d9;
	}

	.hambergerMenu {
		position: relative;
	}

	.hambergerMenu span {
		width: 34px;
		height: 3px;
		background-color: #327072;
		display: block;
		margin: 7px 0px;
		border-radius: 12px;
	}

	.header-left-sec ul,
	.header-right-sec ul {
		list-style: none;
	}

	.header {
		padding: 6px 6px;
		position: relative;
	}

	img.logo {
		object-fit: contain;
		max-width: 100%;
		padding: 5px;
	}

	.notifiRedicon {
		position: absolute;
		top: -4px;
		left: -8px;
		width: 10px;
		height: 10px;
		background-color: #ff0000;
		border-radius: 50%;
	}

	.mobileMenu {
		background-color: #fffcf4;
		width: 276px;
		position: fixed;
		right: -100%;
		top: 0;
		bottom: 0;
		z-index: 999;
		transition: 0.3s;
		border-radius: 10px 0px 0px 10px;
		border: 2px solid #fff6d9;
	}

	.mobileMenuActive {
		right: 0%;
		transition: 0.3s;
		border-radius: 10px 0px 0px 10px;
		border: 2px solid #fff6d9;
		z-index: 999 !important;
	}

	.menuOpenwrapper {
		padding: 18px 24px;
	}

	.menuOpenwrapper {
		display: flex;
		align-items: center;
		width: 238px;
		margin: auto;
	}

	.hambergerMenu {
		color: #294d8f;
		padding-right: 10px !important;
	}

	.menuOpenwrapper h2 {
		font-size: 28px;
		color: #294d8f;
		font-weight: 500;
		margin-bottom: 0;
		margin-left: 36px;
	}

	.mobileMenu-cat li a {
		font-size: 15px;
		color: #5a5a5a;
		font-family: Robotomedium;
	}

	.mobileMenu-cat li {
		border-bottom: 1px solid #e1e1e1;
		padding: 22px 24px !important;
	}

	.mobileMenu-cat {
		width: 238px;
		margin: auto !important;
	}

	.notif-label {
		background-color: #4e4e4e;
		border-radius: 50%;
		float: right;
		color: #fff;
		font-size: 10px;
		padding: 0px 8px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 24px;
		height: 24px;
	}

	.mobileMenu-cat li ul li {
		border-bottom: 0px solid #e1e1e1;
		padding: 10px 0px !important;
	}

	.mobLogout {
		width: 238px;
		margin: auto !important;
		padding: 0px 20px !important;
	}

	.mobLogPadding {
		padding: 20px 5px !important;
		font-size: 17px;
		color: #294d8f;
		font-family: 'Robotomedium';
	}

	.mobLogout ul {
		padding-bottom: 10px !important;
		position: absolute;
		bottom: 0;
	}

	.footer {
		display: none;
	}

	.deskWrapper {
		padding: 5px 10px 70px 10px !important;
		margin-top: 10px;
	}

	.toolsBox {
		position: fixed;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		background-color: #ffffff;
	}

	.text-end {
		transition: 0.3s;
	}

	.tooltextTransiActive {
		margin-right: 25px;
		margin-left: 10px;
		transition: 0.3s;
		opacity: 1;
	}

	.tooltextTransi {
		margin-left: 10px;
		transition: 0.3s;
		opacity: 0;
		width: 0px;
	}

	.tooltextTransiActiveleft {
		margin-right: 0px;
		margin-left: 10px;
		transition: 0.3s;
		opacity: 1;
	}

	.login_privacy p {
		font-size: 13px;
		position: absolute;
		bottom: -16px;
		right: 0;
		left: 0;
		background: #fffcf4;
		padding: 16px 10px;
		border-top: 2px solid #fff6d9;
	}
}

@media only screen and (max-width: 320px) {
	.mobileMenu-cat li {
		padding: 12px 24px !important;
	}

	.mobileMenu-cat li ul li {
		padding: 6px 0px !important;
	}

	.hambergerMenu span {
		width: 28px;
		height: 3px;
		background-color: #327072;
		display: block;
		margin: 6px 0px;
	}

	.menuOpenwrapper h2 {
		font-size: 26px;
	}

	.footerBtn a {
		display: flex;
		align-items: center;
		justify-content: end;
	}

	.footerBtn img {
		height: 34px;
	}
}

.tox-notification {
	display: none !important;
}

.inputCode input {
	width: 148px;
	font-size: 30px;
	letter-spacing: 22px;
	background: transparent;
	border: none;
	margin: 0px;

	outline: none;
	font-weight: 600;
	color: #294d8f;
}

.addcompanyCode > div {
	font-size: 30px;
	font-weight: 600;
	font-family: 'Roboto';
	letter-spacing: 4px;
	color: #294d8f;
}

.addcompanyCode {
	display: flex;
	justify-content: center;
}

.borderCode {
	display: flex;
}

.borderCode > div {
	width: 20px;
	height: 3px;
	background-color: #294d8f;
	margin: 0px 20px 0px 0px;
}

.inputCode {
	position: relative;
}

.saveCode button {
	color: #fff;
	border: none;
	outline: none;
	background: #294d8f;
	padding: 8px 16px;
	border-radius: 8px;
	font-size: 16px;
	font-family: 'Roboto';
}

.saveCode {
	margin-top: 20px;
}

button:disabled,
button[disabled] {
	border: 1px solid #999999;
	background-color: #cccccc;
	color: #666666;
}

.exeSumry td input {
	border: none;
	border-bottom: 0.5px solid black;
}

.saveCode button {
	color: #fff;
	border: none;
	outline: none;
	background: #294d8f;
	padding: 8px 16px;
	border-radius: 8px;
	font-size: 16px;
	font-family: 'Roboto';
}

.saveCode {
	margin-top: 20px;
}

.customDropdown {
	width: 100%;
	margin: auto;
	margin-top: 8px;
}

.customDropdown .form-control {
	outline: none;
	border: none;
	border-bottom: 1px solid #8b8b8b;
	cursor: pointer;
	padding-bottom: 10px;
	padding: 0px 0px 3px 8px;
}

.customDropdown {
	position: relative;
	margin: 0px 8px 0px 0px;
}

.closeButton {
	position: absolute;
	right: 2px;
	top: 0px;
	bottom: 0;
	transform: scaleX(1.3);
	cursor: pointer;
	color: #8b8b8b;
}

.customMenu {
	padding: 0;
	list-style: none;
	position: absolute;
	left: 0;
	text-align: left;
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 2px 20px #bfbfbf;
	border: 1px solid #f6f6f65e;
	border-radius: 0px 0px 14px 14px;
	z-index: 1;
	width: 220px;
	padding-bottom: 8px;
	max-height: 210px;
	overflow-y: auto;
	overflow-x: hidden;
}

.customMenu li {
	padding: 5px 8px;
	cursor: pointer;
}

.addProcess {
	font-family: 'Roboto';
	font-size: 16px;
	transform: scale(1.5);
	margin: 0px 12px 0px 4px;
}

.addedList {
	padding: 0;
	list-style: none;

	margin: auto;
}

.addedList li {
	padding-left: 8px;
	padding-right: 16px;
	outline: none;
	border: none;
	border-bottom: 1px solid #8b8b8b;
	cursor: pointer;
	position: relative;
	text-align: left;
	margin-bottom: 12px;
	padding-bottom: 3px;
}

.deleteItem {
	position: absolute;
	right: 2px;
	top: -5px;
	bottom: 0;
	transform: scaleX(1.3);
	cursor: pointer;
	color: #8b8b8b;
	font-size: 18px;
}

.exeSumry td input:focus {
	border: none;
	border-bottom: 0.5px solid black;
	text-decoration: none;
}

.tableexe th:last-child {
	box-sizing: border-box;
}

.tableexe.tableexesum {
	color: #5a5a5a;
}

.tableexesmry tr:nth-child(even) td {
	padding: 6px;
}

.tableexe th {
	border: 2px solid #8b8b8b;
}

.tableexe th {
	vertical-align: middle;
	color: #5a5a5a !important;
	font-family: 'Roboto';
	font-size: 16px;
}

.tableexesmry td {
	vertical-align: middle;
}

.tableexesmry tr {
	color: #5a5a5a;
	font-family: 'Roboto';
}

.exsummryTable .table tr td th {
	border: 2px solid #8b8b8b;
}

.tableexesmry tr td {
	border: 1px solid #8b8b8b !important;
}

.companydetail_txt {
	font-family: 'Robotomedium';
	text-decoration: underline;
	color: #294d8f;
	font-size: 12px;
	margin-top: 10px;
	margin-bottom: 6px;
	padding-top: 10px;
}

.customRound {
	width: 10px;
	height: 10px;
	background: #6bcecc 0% 0% no-repeat padding-box;
	display: block;
	position: absolute;
	left: -8px;
	top: 5px;
	border-radius: 50%;
}

.deleteItemservices {
	position: absolute;
	right: 9px;
	top: 0px;
	bottom: 0;
	transform: scaleX(1.3);
	cursor: pointer;
	color: #8b8b8b;
	font-size: 18px;

	top: 0px;
	border-radius: 50%;
}

.forgotpw .form-control {
	border-radius: 0;
	font-size: 14px;
	font-family: 'Roboto';
	color: #6d6d6d;
	border-left: 0;
	border-bottom: 1px solid #b8b8b8;
	border-top: 0;
	border-right: 0;
	padding-left: 0;
	padding-right: 0;
	outline: none;
}

.forgotpw .form-control::placeholder {
	color: #000;
	opacity: 0.7;
}

.phoneGroup {
	display: flex;
	align-items: center;
}

.phoneGroup span {
	padding: 0px 5px;
}

.phoneGroup span select {
	font-size: 16px;
	border: none;
	padding: 8px 6px 2px 0px;
	outline: none;
	border-bottom: 1px solid #2f3336;
}

.activeStatusZero {
	border-radius: 12px !important;
	background-color: white !important;
}

.activeStatusOne {
	background: #f0fef6 !important;
	box-shadow: 0px 2px 8px #fff4d0 !important;
	border: 2px solid #00aa45 !important;
	border-radius: 12px !important;
}

.activeStatusThree h2 {
	color: #5a5a5a !important;
}

.activeStatusThree h3 {
	color: #5a5a5a !important;
}

.activeStatusThree {
	background-color: #f7f7f7 !important;
	border: 1px solid #f7f7f7 !important;
}

.form-control:disabled {
	padding-left: 8px;
}

.processFlowMob table,
.processFlow table {
	border: 0px;
}

.processFlowMob table tr th {
	color: #327072;
	background-color: #e4fffe99;
	border: 0;
	font-size: 12px;
	padding: 10px 15px;
	position: relative;
	line-height: 16px;
	height: 36px;
}

.processFlowMob table tr td {
	border: 0;
	padding: 15px 15px 40px 15px;
}

.processFlowMob table tr th:nth-child(1)::before,
.processFlowMob table tr th:nth-child(2)::before {
	background-image: url(./assets/images/arrow-right.png);
	background-repeat: no-repeat;
	background-size: 20px;
	background-position: center center;
	position: absolute;
	content: '';
	top: 0;
	height: 50px;
	width: 50px;
	right: -18px;
	z-index: 9;
	height: 36px;
}

.processFlowMob table tr:last-child td:nth-child(2) {
	background-image: none;
}

.processFlowMob .addedList {
	padding: 0;
	list-style: none;
	max-width: 100%;
	margin: 0;
}

.processFlowMob .customDropdown {
	max-width: 100%;
	margin-top: 8px;
}

.processFlowMob .customDropdown .form-control {
	font-size: 12px;
	padding-left: 0;
	padding-right: 14px;
	padding-bottom: 5px;
	padding-top: 5px;
	color: #707070;
}

.processFlowMob .customDropdown .form-control::placeholder {
	opacity: 0.7;
}

.processFlowMob .customMenu {
	border-radius: 0px 0px 14px 14px;
	width: 110px;
	padding-bottom: 8px;
	max-width: 100%;
}

.processFlowMob .customMenu li {
	font-size: 12px;
	line-height: 14px;
	padding: 8px 5px;
	word-wrap: break-word;
}

.processFlowMob .deleteItem {
	font-size: 14px;
	top: 0;
	right: 5px;
}

.processFlowMob .addedList li span {
	font-size: 12px;
	color: #707070;
	word-break: break-word;
}

.processFlowMob .closeButton {
	right: 0px;
	top: 8px;
	font-size: 11px;
}

.processFlowMob .errorCheck {
	font-size: 12px;
	line-height: 14px;
}

.processFlowMob .addedList li {
	padding-left: 0;
	margin-bottom: 0.5rem;
	margin-top: 12px;
}

.processFlowMob .small_btn {
	padding: 0 !important;
	min-width: 40px;
	border-radius: 40px;
}

.processFlowMob .darkline_btn img {
	margin-right: 0;
	vertical-align: initial;
}

.dummyAddedList {
	margin-top: 12px;
}

.processFlowMob span.customRound {
	left: -10px;
	width: 7px;
	height: 7px;
	top: 10px;
}

.mobileWrapper {
	padding: 16px 16px 80px 14px;
}

.tabStyleMob {
	width: 100%;
	display: block;
	position: relative;
}

.tabStyleMob .tab-content {
	border-bottom: 0px;
	border-bottom-left-radius: 0;
	border-left: 0px;
	border-right: 0px;
	border-bottom-right-radius: 0;
	min-height: 440px;
	padding: 0;
}

.tabStyleMob .nav-tabs {
	border-bottom: 1px solid #294d8f;
	background: #fff;
}

.tabStyleMob .nav-tabs .nav-item.show .nav-link,
.tabStyleMob .nav-tabs .nav-link,
.tabStyleMob .nav-tabs .nav-link:focus,
.tabStyleMob .nav-tabs .nav-link {
	border: 1px solid #327072;
	color: #8b8b8b;
	background-color: #fff;
	font-size: 14px;
	font-weight: 600;
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
}

.tabStyleMob .nav-tabs .nav-item.show .nav-link,
.tabStyleMob .nav-tabs .nav-link.active,
.tabStyleMob .nav-tabs .nav-link:focus,
.tabStyleMob .nav-tabs .nav-link:hover {
	border-top: 1px solid #294d8f;
	border-left: 1px solid #294d8f;
	border-right: 1px solid #294d8f;
	border-bottom: 0px solid #e6e6e6;
	color: #fff;
	background-color: #294d8f;
	font-size: 14px;
	font-weight: 600;
	padding: 10px;
}

.tabStyleMob li.nav-item button {
	padding: 10px;
}

.secondary_btn_mob {
	background-color: #ffffff;
	color: #294d8f;
	border: #294d8f 1px solid;
	padding: 6px 12px;
	font-size: 14px;
	border-radius: 6px;
	font-weight: 500;
	line-height: 1.5;
	margin: 2px;
	position: relative;
	text-align: center;
	min-width: 80px;
}

.addNewObsMob .customObservationbox {
	padding-left: 10px;
	padding-right: 10px;
}

.addNewObsMob label.form-label {
	margin-bottom: 0;
}

@media only screen and (max-width: 420px) {
	.processFlowMob table tr th:nth-child(1)::before,
	.processFlowMob table tr th:nth-child(2)::before {
		top: 0;
		width: 50px;
		right: -25px;
		height: 52px;
	}

	.processFlowMob table tr th {
		background-color: #e4fffe99;
		padding: 10px 15px;
		line-height: 16px;
		height: 52px;
	}

	.processFlowMob table tr th:nth-child(1) span,
	.processFlowMob table tr th:nth-child(2) span,
	.processFlowMob table tr th:nth-child(2) span {
		margin-bottom: 10px;
		display: inline-block;
	}
}

.IRvisitcontainer {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 28px;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.IRvisitcontainer input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

.IRvisitcheckmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 30px;
	width: 30px;
	border-radius: 50%;
}

.IRvisitcontainer:hover input ~ .IRvisitcheckmark {
	background-color: #ccc;
}

.container input:checked ~ .IRvisitcheckmark {
	background-color: #2196f3;
}

.IRvisitcheckmark:after {
	content: '';
	position: absolute;
	display: none;
}

.IRvisitcontainer input:checked ~ .IRvisitcheckmark:after {
	display: block;
}

.IRvisitcontainer .IRvisitcheckmark:after {
	left: 9px;
	top: 5px;
	width: 5px;
	height: 10px;
	border: solid white;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

.IRvisitcheckMarkRound {
	position: absolute;
	top: 0;
	left: 0;
	height: 30px;
	width: 30px;
	border-radius: 50%;
	border: 2px solid;
}

.IRvisitcontainer:hover input ~ .IRvisitcheckMarkRound {
	background-color: #ccc;
}

.container input:checked ~ .IRvisitcheckMarkRound {
	background-color: #fff;
}

.IRvisitcheckMarkRound:after {
	content: '';
	position: absolute;
	display: none;
}

.IRvisitcontainer input:checked ~ .IRvisitcheckMarkRound:after {
	display: block;
}

.IRvisitcontainer .IRvisitcheckMarkRound:after {
	left: 4px;
	top: 4px;
	width: 19px;
	height: 19px;
	border: 2px solid #294d8f;
	transform: rotate(45deg);
	border-radius: 50%;
}

.IRvisitcheckDeactive {
	position: absolute;
	top: 0;
	left: 0;
	height: 30px;
	width: 30px;
	border-radius: 50%;
	border: 2px solid rgba(0, 0, 0, 0.4);
}

.IRvisitcontainer:hover input ~ .IRvisitcheckDeactive {
	background-color: #ccc;
}

.container input:checked ~ .IRvisitcheckDeactive {
	background-color: #fff;
}

.IRvisitcheckDeactive:after {
	content: '';
	position: absolute;
	display: none;
}

.IRvisitcontainer input:checked ~ .IRvisitcheckDeactive:after {
	display: none;
}

.IRvisitcontainer .IRvisitcheckDeactive:after {
	left: 4px;
	top: 4px;
	width: 19px;
	height: 19px;
	border: 3px solid #294d8f;
	transform: rotate(45deg);
	border-radius: 50%;
}

.IRvisitTickcontainer {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 28px;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.IRvisitTickcontainer input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

.IRvisitTickcheckmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 30px;
	width: 30px;
	border-radius: 50%;
	border: 2px solid;
	background-color: #fff;
}

.IRvisitTickcontainer:hover input ~ .IRvisitTickcheckmark {
	background-color: #fff;
}

.IRvisitTickcheckmark:after {
	content: '';
	position: absolute;
	display: none;
}

.IRvisitTickcontainer input:checked ~ .IRvisitTickcheckmark:after {
	display: block;
}

.IRvisitTickcontainer .IRvisitTickcheckmark:after {
	left: 14px;
	top: -4px;
	width: 10px;
	height: 22px;
	border: solid #00aa45;
	border-width: 0 3px 3px 0;
	transform: rotate(45deg);
}

.IRvisitTickcontainer input:checked ~ .IRvisitTickcheckmark {
	background-color: #fff;
}

.IRvisitTickcheckmark:before {
	content: '';
	right: -4px;
	top: -1px;
	width: 10px;
	height: 13px;
	background-color: white;
	position: absolute;
}

.DesktopIARVisit hr {
	border: none;
	border-top: 4px dashed;
	position: absolute;
	width: 42px;
	z-index: 1;

	opacity: 0.5;
	transform: rotate(90deg);
	top: 50px;
	left: 10px;
	color: #00aa45;
}

.DesktopIARVisit {
	position: relative;
}

.secondary_btn_disabled {
	background-color: #b8b8b8;
	color: #6d6d6d;
	border: #b8b8b8 1px solid;
	padding: 8px 15px;
	font-size: 14px;
	border-radius: 6px;
	font-weight: 500;
	line-height: 1.5;
	margin: 2px;
	position: relative;
	display: inline-block;
	text-align: center;
	min-width: 80px;
	pointer-events: none;
}

#content {
	position: fixed;
	top: -10000%;
}

.pdf {
	z-index: -1;
	width: 606px;
}

.pdfview {
	margin-left: 200px;
}

.pdfPreview {
	margin-left: 118px;
}

.pdfview .border {
	margin-top: 3px;
	border: 1px solid black !important;
	width: 602px;
}

.pdfPreview .border {
	margin-top: 3px;
	border: 1px solid black !important;
	width: 816px;
}

.DesktopIARVisit {
	position: relative;
}

.iarConformcheckcontainer {
	display: block;
	position: relative;
	padding-left: 35px;

	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	user-select: none;
	top: 14px;
	left: 38px;
	font-size: 14px;
	padding-top: 1px;
	color: #6d6d6d;
}

.iarConformcheckcontainer input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

.iarConformcheckcheckmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 25px;
	width: 25px;

	border-radius: 6px;
	border: 2px solid #6d6d6d;
}

.iarConformcheckcontainer:hover input ~ .iarConformcheckcheckmark {
	background-color: #ccc;
}

.iarConformcheckcontainer input:checked ~ .iarConformcheckcheckmark {
	background-color: #294d8f;
}

.iarConformcheckcheckmark:after {
	content: '';
	position: absolute;
	display: none;
}

.iarConformcheckcontainer input:checked ~ .iarConformcheckcheckmark:after {
	display: block;
}

.iarConformcheckcontainer .iarConformcheckcheckmark:after {
	left: 9px;
	top: 5px;
	width: 5px;
	height: 10px;
	border: solid white;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

.mobileIARVisit .hrSmall {
	border: none;
	border-top: 4px dashed;
	position: absolute;
	width: 42px;
	z-index: 1;

	opacity: 0.5;
	transform: rotate(90deg);
	top: 50px;
	left: 10px;
}

.mobileIARVisit {
	position: relative;
}

.mobileIARVisit .hrBig {
	border: none;
	border-top: 4px dashed;
	position: absolute;
	width: 110px;
	z-index: 1;

	opacity: 0.5;
	transform: rotate(90deg);
	top: 84px;
	left: -24px;
}

.subTitle {
	color: #294d8f;
	font-size: 12px;
	font-weight: 600;
	padding: 0px 50px;
	margin: 0px;
	margin-top: 0px;
}

.breadcrumbMobile {
	background-color: #fffcf4;
	padding: 7px 0px 7px 0px;
	display: grid;
	align-items: center;
}

.mainTitlefront {
	color: #294d8f;
	font-size: 18px;
	font-weight: 600;
	padding: 0px 50px;
	margin: 0px;
}

.leftArrow {
	font-size: 44px;
	color: #294d8f;
	position: absolute;
	left: 16px;
	transform: scale(1.1, 0.7);
}

.react-datepicker-popper[data-placement^='top']
	.react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^='bottom']
	.react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^='top']
	.react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^='bottom']
	.react-datepicker__triangle::after {
	left: unset !important;
	right: 200px !important;
}

.react-datepicker__input-container input {
	width: 100%;
	border: none;
	border-bottom: 1px solid #5a5a5a8a;
	color: #5a5a5a;
	padding: 6px 3px;
	outline: none;
}

.react-datepicker__input-container {
	width: 100% !important;
}

.tabPadding button {
	padding: 15px !important;
	border-radius: 20px 20px 0px 0px !important;
}

.DelObservation {
	padding: 0;
	outline: none;
	border: none;
}

.scpMenuWrapper > span {
	list-style: none;
	display: flex;
	padding: 0;
}

.scpMenuWrapper > span {
	list-style: none;
	display: block;
	padding: 0px 0px 0px 4px;
	position: absolute;
	top: 43px;
	background: #fffcf4;
	left: -44px;
	width: 113px;
	border: 1px solid #d3bf7c;
	border-radius: 4px;
	transform: rotate(90deg);
	z-index: 999;
}

.scpMenuWrapper > span button {
	transform: rotate(-90deg);
}

.scpMenuWrapper {
	position: absolute;
	margin-bottom: 0px;
	top: 4px;
	right: 0;
}

.rdw-editor-wrapper {
	box-sizing: content-box;
	border: 1px solid rgba(0, 0, 0, 0.4);
	padding: 8px 10px;
	border-radius: 5px;
}

.coverFrame {
	position: absolute;
	height: 50px;
	width: 25px;
	background-color: #fffcf4;
	right: 16px;
	top: 0;
}

.rotateCloseOne {
	margin: 0px 0px !important;
	transform: rotate(45deg);
}

.rotateCloseTwo {
	transform: rotate(-45deg);
	margin: -1px 0px !important;
}

.po-relative {
	position: relative;
}

.loginField:nth-child(1) input {
	padding-left: 6px !important;
	padding-right: 6px !important;
}

.loginField:nth-child(2) input {
	padding-left: 6px !important;
	padding-right: 30px !important;
}

.eyeClass {
	position: absolute;
	top: 36px;
	right: 4px;
	cursor: pointer;
	width: 20px;
	height: 20px;
}

.login_privacy {
	text-align: center;
	margin-top: 40px;
}

.login_privacy a {
	color: #327072 !important;
	font-weight: 600;
}

.utilButton {
	padding: 10px 64px !important;
}

.utilButton img {
	width: 20px !important;
}

.displaycenter {
	display: flex !important;
	align-items: center;
	justify-content: center;
}

.displaycenter svg {
	margin-right: 6px !important;
}

.btn-check + .btn:hover {
	background-color: unset;
	color: #294d8f;
}

.iarabsolute {
	position: absolute;
	right: -16px;
	width: auto;
	top: -10px;
}

.notificationHeight {
	width: 360px;
	max-height: 256px;
	overflow-y: auto;
	overflow-x: hidden;
}

.NotifiHeader {
	font-size: 18px;
	font-family: 'Robotomedium';
	font-weight: 700;
	border-bottom: 1px solid;
}

.NotifiHeaderTwo {
	font-size: 18px;
	font-family: 'Robotomedium';
	font-weight: 700;
	border-bottom: 1px solid;
}

.NotifiHeaderTwo {
	margin-top: 5px !important;
}

@media only screen and (max-width: 768px) {
	.iarabsolute {
		position: absolute;
		right: -20px;
		width: auto;
		top: -10px;
	}
	.utilButton {
		padding: 10px 28px !important;
	}

	.deskWrapper {
		min-height: 100% !important;
	}

	.mobileButtonAbsolute {
		position: absolute;
		right: 0;
		left: 0;
		text-align: center;
		display: flex;
		justify-content: space-between;
	}

	.tabStyleMob li.nav-item button {
		padding: 0;
		height: 40px;
	}

	.tabStyleMob .nav-tabs .nav-item.show .nav-link,
	.tabStyleMob .nav-tabs .nav-link.active,
	.tabStyleMob .nav-tabs .nav-link:focus,
	.tabStyleMob .nav-tabs .nav-link:hover {
		padding: 0;
		height: 40px;
	}
}

.customMenu::-webkit-scrollbar {
	width: 4px;
}

.customMenu::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px rgb(227, 222, 222);
	border-radius: 15px;
}

.customMenu::-webkit-scrollbar-thumb {
	background: rgb(156, 148, 148);
	border-radius: 15px;
}

@media only screen and (max-width: 992px) {
	.tabPadding button {
		padding: 6px !important;
	}

	.psmall_btn {
		border: 2px solid #b8b8b8 !important;
		display: flex !important;
		align-items: center;
		justify-content: center !important;
		padding: 3px 0px !important;
		min-width: 40px !important;
		margin: 6px auto;
		border-radius: 10px;
	}

	.psmallAdd_btn {
		border: 2px solid #294d8f !important;
		display: flex !important;
		align-items: center;
		justify-content: center !important;
		padding: 3px 6px !important;
		min-width: 40px !important;
		margin: 6px auto;
		border-radius: 8px;
		font-size: 10px;
		background-color: #e4fffe;
	}

	.psmallAdd_btn img,
	.psmall_btnDelete img {
		margin-right: 3px !important;
	}

	.psmall_btnDelete {
		border: 2px solid #b8b8b8 !important;
		display: flex !important;
		align-items: center;
		justify-content: center !important;
		padding: 4px 3px !important;
		min-width: 40px !important;
		margin: 6px auto;
		border-radius: 8px;
		font-size: 10px;
		background-color: #fff;
	}

	.Psecondary_btn_mob {
		background-color: #ffffff;
		color: #294d8f;
		border: #294d8f 2px solid;
		padding: 6px 10px;
		font-size: 12px;
		border-radius: 6px;
		font-weight: 500;
		line-height: 1.5;
		margin: 2px;
		position: relative;
		display: inline-block;
		text-align: center;
		min-width: 80px;
	}

	.Psecondary_btn_mob:hover {
		background-color: #294d8f;
		color: #fff;
	}

	.Psecondary_btn_mob:hover a {
		color: #fff;
	}
}

.secondary_btn:hover a {
	color: #fff !important;
}

.notificationPaddingbottom {
	padding-bottom: 96px;
}

.select {
	position: relative;
}

.selectArrow {
	position: absolute;
	bottom: 10px;
	cursor: pointer;
	right: 12px;
	font-size: 22px;
}

.select select option {
	color: #000;
}

.primaryhover:hover {
	color: unset !important;
	cursor: not-allowed;
}

.frontendBtn {
	background-color: #ffffff;
	color: #294d8f;
	border: #294d8f 3px solid;
	padding: 8px 15px;
	font-size: 14px;
	border-radius: 6px;
	font-weight: 500;
	line-height: 1.5;
	margin: 2px;
	position: relative;
	display: inline-block;
	text-align: center;
	min-width: 80px;
}

.frontendBtn:hover {
	background-color: #294d8f;
	color: #fff !important;
}

.breadcrumb-item + .breadcrumb-item::before {
	content: '>' !important;
	font-weight: 300;
	transform: scaleY(2);
}

@media only screen and (max-width: 420px) {
	.mobileButtonAbsolute {
		display: flex;
		justify-content: space-between;
	}
}

.loaderIAR {
	position: absolute;
	right: 0;
	left: 0;
	bottom: 0;
	top: 0;
	margin: auto;
	width: 200px;
}

.tab-content {
	position: relative;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active,
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
	border-top: 3px solid #00aa45 !important;
	border-left: 3px solid #00aa45 !important;
	border-right: 3px solid #00aa45 !important;
}

.pdfdownload[disabled] {
	color: #294d8f;
	background-color: white;
}

.processArrowdown {
	position: absolute;
	top: 0px;
	right: -4px;
	font-size: 18px;
	color: #000;
	opacity: 0.5;
}

.alertMessagepopup {
	width: 282px;
	right: 0;
	margin: auto;
}

.warningAlert {
	padding: 0px !important;
}

.bgcolorTransparent:disabled {
	background-color: transparent !important;
	border: none !important;
}

.spanPtag p {
	margin-bottom: 0px;
}

.spanPtag {
	color: #6c6c6c;
}

.spanPtag ul li {
	list-style: unset !important;
}

.threeDot img {
	color: #fffcf4;
	border: 2px solid #ffde9b;
	border-radius: 5px;
	padding: 5px 0px;
}

.disableBtn {
	opacity: 0.5;
	cursor: none;
}

.iarabsolute p {
	color: #5a5a5a !important;
	font-size: 12px !important;
	font-weight: 500 !important;
	font-family: 'Robotomedium';
	margin-bottom: 0px;
}

@media only screen and (max-width: 420px) {
	.iarabsolute {
		width: 170px;
	}
}

@media only screen and (max-width: 360px) {
	.iarabsolute {
		width: 122px;
	}

	.iarabsolute div p {
		font-size: 10px !important;
	}
}

.loginPlace:placeholder-shown {
	font-size: 12px;
}

.loaderPDF {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	text-align: center;
	background: #ffffffe0;
	z-index: 9999999;
	overflow: hidden;
	height: 100%;
}

.loaderPDF img {
	width: 160px;
	padding-top: 20%;
}

.breadcrumb-item + .breadcrumb-item::before {
	margin-right: 4px;
	margin-left: 4px;
}

.addCompanyDisable:disabled {
	background-color: #6d6d6d !important;
	color: #281f1f !important;
	font-family: 'Robotomedium' !important;
	opacity: 0.7;
}

.secondary_btn:hover img {
	filter: invert(1000%) sepia(1000%) saturate(0) hue-rotate(95deg)
		brightness(134%) contrast(106%);
}

.tableexesmry tr:nth-child(even) td {
	border-bottom: 1 !important;
}

.scrollCustom {
	overflow-x: hidden;
}

.scrollCustom::-webkit-scrollbar {
	width: 3px;
}

.scrollCustom::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px grey;
	border-radius: 10px;
}

.scrollCustom::-webkit-scrollbar-thumb {
	background: #294d8f;
	border-radius: 10px;
}

.primary_btnGallery {
	color: #ffffff;
	border: #294d8f 1px solid;
	padding: 8px 60px;
	font-size: 14px;
	border-radius: 6px;
	font-weight: 500;
	line-height: 1.5;
	margin: 2px;
	position: relative;
	display: inline-block;
	text-align: center;
	min-width: 80px;
}

.warningMsg {
	color: #ff0000;
	font-weight: 600;
	font-size: 20px;
	margin-bottom: 15px;
}

.warningMsgTwo {
	color: #294d8f;
	font-weight: 500;
	margin: 0;
}

.EditorBorder .tox-tinymce {
	border: 2px solid #6bcecc !important;
}

.nodataFound img {
	filter: invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg)
		brightness(95%) contrast(80%);
}

.tabWebview .nav-link {
	margin-top: 10px !important;
}

.tabWebview .active {
	margin-top: 0px;
}

.tabWebview .nav-link:not(.active) {
	padding: 10px 0px 11px 0px !important;
	margin-top: 20px !important;
	border: 2px solid #b8b8b8 !important;
}

.form-select:disabled {
	background-color: transparent;
}

.widthGallery {
	width: 70% !important;
}

.processDeskBtn {
	background-color: #fffcf4 !important;
	border: 2px solid #6bcecc;
	min-width: 158px !important;
}

.inputCaptionBlock {
	height: 20px;
	background-color: transparent;
	width: 250px;
	margin: auto;
	position: absolute;
	right: 0;
	left: -25px;
	top: 5px;
}

.table > :not(caption) > * > * {
	background-color: transparent !important;
}

.processMobileHeader {
	background-color: #e4fffe99;
}

@media only screen and (max-width: 768px) {
	.nav-tabs .nav-item.show .nav-link,
	.nav-tabs .nav-link.active,
	.nav-tabs .nav-link:focus,
	.nav-tabs .nav-link:hover {
		border-top: 3px solid #294d8f !important;
		border-left: 3px solid #294d8f !important;
		border-right: 3px solid #294d8f !important;
	}
}
*::-webkit-scrollbar {
	display: none;
}

.iFrameContainer ul {
	list-style: none;
}
