.mainContainers{
    width:50%;
    margin-top: 20px;
}
.mainParagraph{
    
                color:gray; margin-bottom: 30px;font-size: 18px;
         
}
.accordionHelp{
    margin-left: 15px;
}
 .cardBodHelp {
  
     font-size: 18px;
 }

@media screen and (max-width: 768px){
    .mainContainers{
        width: 100%;
       
    }
        .accordionHelp {
            padding:10px 0px;
                margin-left: 0px;
            
        }
        .mainParagraph {
    
      margin-bottom:15px;
            font-size: 16px;
    
        }
        .cardHelp{
            margin-left: 0px;
            padding: 0px;
            margin:15px 0px;
        }
        .cardHeader{
            padding: 5px 0px;

        }
        .cardBodHelp{
            padding:10px 0px;
            font-size: 16px;
        }
}