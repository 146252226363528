.image_two img {
	max-width: 100%;
	width: 250px;
	margin-left: 257px;
	padding-top: 14px;
}

.custom_container_gallery {
	max-width: 630px;
	width: 100%;
	margin: 0px 0px 0px 150px;
}

.hr_line {
	height: 1px;
	width: 180px;
	border-width: 0;
	color: black;
	background-color: black;
	margin-top: 60px;
}

.footer_points {
	font-size: 8px;
	line-height: 13px;
	list-style-type: none;
}

.footer_images_table_2 {
	margin-left: 0px;
	margin-top: 67px;
	display: flex;
	align-items: self-end;
}

.footer_images_table {
	margin-left: 5px;
	margin-top: 5px;
}

.footer_images_text {
	font-size: 6px;
	color: #50ab92;
}

.gallery_img_div {
	display: flex;
	justify-content: space-around;
}

.gallery_img_div img {
	width: 300px;
	height: 150px;
	object-fit: contain;
}

.custom_container_pageNine {
	max-width: 630px;
	width: 100%;
	margin: 0px 0px 0px 88px;
}

.img_txt {
	font-size: 12px;
	color: #50ab92;
	font-family: 'Roboto';
}

.footer_images_gallerySection {
	margin-left: 0px;
	margin-top: 5px;
	display: flex;
	align-items: self-end;
}

.imgdiv img {
	display: flex;
}

.gallery_img_div {
	float: left;

	max-width: 28%;
	display: flex;
	flex-wrap: wrap;
	margin: 0px 115px 0px 20px;
}

.imgdiv {
	display: flex;
	flex-direction: column;
	flex-basis: 30%;
}

.imgCaption {
	color: #50ab92;
	font-size: 15px;
	font-family: 'Arial';
	width: 295px;
	text-align: center;
}

.header {
	color: #50ab92;
	font-size: 17px;
	font-family: 'Arial';
	font-weight: 600;
	margin: 20px 0px 20px 5px;
}

.imgtxtSectn {
	position: relative;
}

.switchImgTxt p {
	font-family: 'Arial';
	font-size: 12px;
	color: 000;
	color: black;
	position: absolute;
	top: 9px;
	left: 278px;
}
