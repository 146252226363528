.wraper {
	padding: 10px 20px;
}

.headertxt p {
	font-weight: 700;
	font-size: 22px;
	color: #294d8f;
	background: #fffcf4;
	padding: 10px 10px 10px 28px;
}
.headertxtMobile p {
	font-weight: 700;
	font-size: 22px;
	color: #294d8f;
	padding: 10px 10px 10px 28px;
	margin-top: 50px;
}

.subtxt p {
	font-family: 'Robotomedium';
	font-size: 20px;
	color: #294d8f;
}

.name {
	color: #5a5a5a;
	font-family: 'Robotomedium';
	font-size: 22px;
	padding: 0px;
	margin: 14px 0px;
	text-transform: capitalize;
}

.lessthan {
	padding-right: 10px;
}

.design {
	color: #5a5a5a;
	font-family: 'Roboto';
	font-size: 16px;
	padding: 0px;
	margin-bottom: 6px;
}

.cntry {
	color: #5a5a5a;
	font-family: 'Roboto';
	font-size: 13px;
	text-transform: capitalize;
	font-size: 16px;
}

.email {
	color: #294d8f;
	font-family: 'Robotomedium';
	font-size: 14px;
}

.emailaddrs {
	color: #5a5a5a;
	font-family: 'Robotomedium';
	font-size: 16px;
	padding-left: 5px;
}

.phone {
	color: #294d8f;
	font-family: 'Robotomedium';
	font-size: 14px;
}

.phonenum {
	color: #5a5a5a;
	font-family: 'Robotomedium';
	font-size: 16px;
	padding-left: 5px;
}

.sign {
	color: #294d8f;
	font-family: 'Robotomedium';
	font-size: 18px;
	margin-top: 30px;
}

.signature {
	background-color: #ffffff;
	color: #294d8f;
	border: #294d8f 2px solid;
	padding: 8px 15px;
	font-size: 14px;
	border-radius: 10px;
	font-weight: 500;
	line-height: 1.5;
	margin: 2px;
	position: relative;
	display: inline-block;
	text-align: center;
	min-width: 290px;
	font-family: 'Robotomedium';
}

input[type='file'] {
	display: none;
}

.wraper img {
	width: 14px;
	margin-right: 10px;
}
.details {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.signatureImg img {
	width: 160px;
	height: 100px;
}
.updateSign svg {
	font-size: 20px;
	margin-right: 6px;
}
@media screen and (max-width: 992px) {
	.details {
		display: block;
	}
}
@media screen and (max-width: 768px) {
	.subtxt p {
		font-size: 16px;
	}
	.details {
		display: block;
	}
	.design {
		margin: 7px 0px;
	}
	.sign {
		margin-top: 0px;
	}
}
