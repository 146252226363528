.datepickr_txt {
	font-family: 'Robotomedium';
	color: #294d8f;
}

.secondary_date_btn {
	background-color: #294d8f;
	color: #ffffff;
	border: #294d8f 1px solid;
	padding: 8px 15px;
	font-size: 14px;
	border-radius: 6px;
	font-weight: 500;
	line-height: 1.5;
	margin: 2px;
	position: relative;
	display: inline-block;
	text-align: center;
	min-width: 250px;
}

.date_btn {
	text-align: center;
}

.date_picker {
	margin-bottom: 30px;
}
