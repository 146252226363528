.section{
    margin-bottom: 30px;
}
.paragraph{
    font-size: 18px;
}
.heading{
    color:#5A5A5A;
    font-size: 20px;
    font-weight: bold;
}

@media screen and (max-width: 768px) {
    .paragraph{
        font-size: 15px;
    }
}
