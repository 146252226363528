.desktopLanguage > ul {
	display: flex;
	padding: 0;
	list-style: none;
	justify-content: flex-end;
	margin: 0;
}

.desktopLanguage li {
	list-style: none;
}

.desktopLanguage > ul li ul {
	padding: 0;
}

.desktopLanguage > ul li ul li:nth-child(1) {
	font-size: 18px;
	color: #294d8f;
	font-family: 'Roboto';
	font-weight: 600;
}

.desktopLanguage > ul > li:nth-child(2),
.desktopLanguage > ul > li:nth-child(3) {
	margin: 0px 0px 0px 23px;
	font-size: 16px;
	color: #294d8f;
	font-family: 'Roboto';
	padding: 0px 16px;
	display: flex;
	align-items: center;
	border: 2px solid;
	border-radius: 8px;
	width: 138px;
	text-align: center;
	justify-content: center;
	cursor: pointer;
	font-family: 'Roboto';
	font-weight: 600;
}

.desktopLanguage > ul > li:nth-child(2):hover,
.desktopLanguage > ul > li:nth-child(3):hover {
	background-color: #294d8f;
	color: #fff;
	font-family: 'Roboto';
	font-weight: 600;
}

.activecolor {
	background-color: #294d8f;
	color: #fff !important;
}

.notificationMaintxt p {
	padding: 0px;
	margin: 0px;
}

.notificationMaintxt {
	color: #5a5a5a;
	font-size: 15px;
	font-family: 'Roboto';
	font-weight: 600;
	padding: 0px 0px;
	font-family: 'Robotomedium';
	margin-bottom: 8px;
}

.notificationSubtxt {
	font-size: 13px;
	color: #6d6d6d;
	font-family: 'Roboto';
	padding: 0px 20px 0px 0px;
	width: 275px;
}

.notificationSubtxtDate span {
	display: block;
	font-family: 'Roboto';
	font-size: 12px;
	color: #8b8b8b;
}

.toolsMaintxt p {
	color: #294d8f;
	font-size: 16px;
	font-family: 'Roboto';
	font-weight: 700;
	padding: 10px 0px;
	margin: 0px;
}

.toolsSubtxt p {
	font-size: 11px;
	color: #6d6d6d;
	font-family: 'Roboto';
	padding: 0px 20px 0px 0px;
	width: 275px;
}

.hr {
	margin: 0rem 0;
	color: inherit;
	border: 0;
	border-top: 1px solid;
	opacity: 0.25;
}

.headingText {
	color: #294d8f;
	text-align: left;
	font: normal normal bold 22px/27px Roboto;
	letter-spacing: -0.29px;
	color: #294d8f;
	opacity: 1;
	padding-left: 15px;
}
@media only screen and (max-width: 768px) {
	.desktopLanguage > ul > li:nth-child(2),
	.desktopLanguage > ul > li:nth-child(3) {
		margin: 0px 0px 0px 8px;
		font-size: 12px;
		color: #294d8f;
		font-family: 'Roboto';
		padding: 0px 6px;
		display: flex;
		align-items: center;
		border: 2px solid;
		border-radius: 8px;
		width: 56px;
		text-align: start;
		justify-content: center;
		cursor: pointer;
		font-family: 'Roboto';
		font-weight: 600;
		height: 37px;
	}
	.desktopLanguage > ul li ul li:nth-child(1) {
		font-size: 13px;
	}
	.mobilePadding {
		padding: 0px !important;
	}
	.paddingHeader {
		padding: 6px 8px !important;
	}
	.listInlineItem {
		margin-right: 0rem !important;
	}
}
.closeButton {
	font-size: 28px;
}
.closeButton path {
	stroke: #294d8f !important;
	stroke-width: 3px;
}
.userStyle {
	font-size: 30px;
	margin-right: 4px;
	margin-bottom: 4px;
}
.headingTitle h1 {
	font-size: 21px;
	font-family: 'RobotoBold';
	color: #294d8f;
	margin-bottom: 0px;
	text-transform: capitalize;
	margin-left: 50px;
	margin-top: 2px;
}
.headingTitle h3 {
	font-size: 11px;
	font-family: 'RobotoBold';
	color: #294d8f;
	margin-bottom: 0;
	margin-top: 3px;
}
.headingTitle {
	display: flex;
	padding: 2px 2px 4px 10px;
	align-items: center;
}
.headingTitle svg {
	font-size: 22px;
	transform: scaleX(1.5);
	margin-right: 12px;
	color: #294d8f;
}
