.custom_container_table {
	width: 100%;
}

.SCP_section .teble_head {
	text-align: center;
}

.SCP_section td {
	font-family: 'Arial';
}

.SCP_section table {
	width: 100%;

	border-spacing: '0';
}

.teble_head {
	background-color: #294d8f;
	color: aliceblue;
	text-align: center;
}

.hr_line {
	height: 1px;
	width: 180px;
	border-width: 0;
	color: black;
	background-color: black;
}

.footer_points {
	font-size: 8px;
	line-height: 13px;
	list-style-type: none;
	color: black;
}

.footer_images_table {
	margin-left: 0px;
	margin-top: 12px;
	display: flex;
	align-items: self-end;
	width: 50px;
}

.footer_images_table {
	margin-left: 5px;
	margin-top: 5px;
}

.footer_images_text {
	font-size: 8px;
	color: #294d8f;
}

.bottom_div {
	margin-top: 17px;
}

tbody tr td p {
	color: black;
	text-align: left;
	padding: 5px;
}

.table_header {
	font-size: 17px;
	color: #294d8f;
	font-family: 'Arial';
	font-weight: 600;
	margin-left: 10px;
}
