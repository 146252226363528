.custom_container_pagethree {
	width: 100%;
}

.flowdiagram_headertxt {
	color: black;
	margin-bottom: 3px;
	border-bottom: 1px solid black;
	line-height: 5px;
	padding-bottom: 5px;
}

.processflow_header {
	font-size: 17px;
	color: #294d8f;
	font-family: 'Arial';
	margin-top: 20px;
	font-weight: 600;
}

.flowdiagram_header {
	display: flex;
	justify-content: space-between;
	font-size: 10px;
	font-weight: 700;
	margin: 0px 30px 0px 30px;
	color: black;
}

.flowdiagram_header_diagram {
	font-size: 13px;
	font-weight: bold;
	margin: 0px 0px 0px 160px;
	width: 720px;
}

.flowdiagram_header_diagram p {
	margin-bottom: 0px;
}

.flowdiagram_sec_one {
	display: flex;
	justify-content: space-between;
	font-size: 9px;
	font-weight: bold;
	color: black;
}

.flowdiagram_sec_two {
	display: flex;
	justify-content: space-between;
	font-size: 10px;
	font-weight: bold;
	color: black;
}

.text_article_two {
	font-size: 13px;
	text-align: justify;
	line-height: 1.5rem;
	color: black;
}

.gg-arrow-left {
	box-sizing: border-box;
	position: relative;
	display: block;
	transform: scale(var(--ggs, 1));
	width: 22px;
	height: 22px;
}

.gg-arrow-left::after,
.gg-arrow-left::before {
	content: '';
	display: block;
	box-sizing: border-box;
	position: absolute;
	left: 3px;
}

.gg-arrow-long-right-processflow {
	box-sizing: border-box;
	position: relative;
	right: 22px;
	top: 18px;
	display: block;
	transform: scale(var(--ggs, 1));
	border-top: 2px solid transparent;
	border-bottom: 2px solid transparent;
	box-shadow: inset 0 0 0 2px;
	width: 16px;
	height: 6px;
	color: black;
	padding: 0;
}

.gg-arrow-long-right-processflow::after {
	content: '';
	display: block;
	box-sizing: border-box;
	position: absolute;
	width: 6px;
	height: 6px;
	border-top: 2px solid;
	border-right: 2px solid;
	transform: rotate(45deg);
	right: 0;
	bottom: -2px;
}

.flowdiagram_left_txt_processflow {
	text-align: right;
	position: relative;
	width: 151px;
	padding-right: 11px;
	color: black;
	font-family: 'Arial';
}

.flowdiagram_mid_txt {
	padding: 10px;
	text-align: center;
	border: 1px solid black;
	background: #d4d4d4;
	width: 150px;
	color: black;
	font-family: 'Arial';
	position: relative;
}

.flowdiagram_right_txt_processflow {
	position: relative;
	width: 150px;
	padding-left: 11px;
	color: black;
	font-family: 'Arial';
}

.flowdiagram_right_txt_processflow::before {
	background: url(../../../assets/images/right.png) no-repeat left center;
	background-size: 20px;
	content: '';
	position: absolute;
	width: 20px;
	height: 20px;
	left: -8px;
	top: 15%;
}
.flowdiagram_left_txt {
	text-align: end;
}

.flowdiagram_mid_txt_two {
	padding: 10px;
	border: 1px solid black;
	background-color: #bac4df94;
}

.header {
	font-size: 9px;
}

.fig_text {
	font-size: 13px;
	color: #294d8f;
	font-style: italic;
	text-align: center;
	margin: 0px 0px 0px 104px;
	width: 720px;
}

.prject_implementd_three {
	margin-top: 196px;
	font-size: 8px;
	color: #294d8f;
	text-align: center;
}

.text_article_two_proces {
	color: rgb(0, 0, 0);
	font-size: 14px;
	font-family: 'ArialRegular';
}

.downarrow {
	width: 11px;
	height: 16px;
	position: relative;
	top: -8px;
	left: 65px;
}

.flowdiagram_processflow {
	display: flex;
	justify-content: space-between;
	font-size: 13px;
	font-weight: bold;
	align-items: center;
	padding: 3px 0px;
}

.flowdiagram_mid_txt_right {
	padding-right: 10px;
	text-align: right;

	font-family: 'Arial';

	color: black;
}

.rightimg {
	width: 15px;
	height: 14px;
	position: relative;
}

.arrows {
	position: relative;
	top: 18px;
	right: 0px;
}

.flowdiagram_header {
	display: flex;
	justify-content: space-between;
	font-size: 13px;
	font-weight: bold;
	font-family: 'Arial';
	margin: 10px 0px 20px 75px;
	width: 498px;
}

.right_vertical_line {
	position: relative;
}

.right_vertical_line p {
	border-right: 2px solid #232423;
}

.left_vertical_line {
	position: relative;
}

.left_vertical_line p {
	border-left: 2px solid #232423;
}
