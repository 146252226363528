.companydetail_txt {
	font-family: 'Robotomedium';
	color: #294d8f;
}

.company_name {
	font-size: 20px;
	font-family: 'Robotomedium';
}

.company_details_subtxt {
	font-size: 14px;
	font-family: 'Robotomedium';
}

.company_adress {
	font-family: 'Roboto';
	font-size: 13px;
	color: #5a5a5a;
}

.contact_persons_details {
	margin-top: 40px;
}

.contact_person_txt {
	font-size: 15px;
	font-family: 'Robotomedium';
	color: #327072;
}

.contact_person_lhs {
	font-family: 'Robotomedium';
	color: #294d8f;
	font-size: 12px;
}

.contact_person_rhs {
	font-size: 11px;
	padding-left: 8px;
}
.productsCom {
	line-height: 15px;
	font-size: 16px;
}
