.logo_img {
	width: 350px;
	margin-bottom: 10px;
}

#demo {
	text-align: center;
}

.header {
	margin-top: 0px;
	color: #294d8f;
	font-size: 25px;
	font-family: 'Robotomedium';
}

.image_two img {
	width: 100%;
}

.cmpnyHeader {
	font-size: 36px;
	color: #213949;

	letter-spacing: 0.2px;
	font-weight: 800;
	font-family: 'Roboto';
	margin-bottom: 15px;
}

.cmpnyName {
	font-size: 16px;
	color: #50ab92;
	margin: 0px;
	font-weight: 700;
	letter-spacing: 0.2px;
	font-family: 'Roboto';
	text-transform: uppercase;
}

.cmpnyregn {
	font-size: 16px;
	color: #50ab92;
	margin: 0px;
	letter-spacing: 0.2px;
	font-weight: 700;
	font-family: 'Roboto';
	text-transform: uppercase;
}

.cmpnycntry {
	font-size: 16px;
	font-weight: 700;
	text-transform: uppercase;
	color: #50ab92;
	margin: 0px;
	letter-spacing: 0.2px;
	font-family: 'Roboto';
}

.date {
	color: #213949;
	font-size: 18px;
	font-family: 'Roboto';
	margin-bottom: 100px;
}

.imgtxtSectn {
	margin-top: 15px;
	position: relative;
}

.switchImgTxt p {
	font-family: 'Arial';
	font-weight: 600;
	font-size: 16px;
	color: #294d8f;
	position: absolute;
	top: 10px;
	left: 43px;
}
