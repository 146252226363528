.logoHeader {
	display: flex;
	margin-top: 75px;
	margin-left: 94px;
	align-items: center;
	opacity: 0.6;
}

.logo {
	width: 167px;
	height: 50px;
}

.logoRight {
	width: 169px;
	margin-left: 269px;
}

.header {
	margin-left: 97px;
	margin-top: 24px;
	color: #50ab92;
	font-size: 17px;
	font-weight: 700;
	font-family: 'Arial';
}

.executiveWraper {
	width: 603px;
	font-family: 'Arial';
	color: black;
	font-size: 13px;
	text-align: justify;
	margin-left: 100px;
	line-height: 0.6cm;
}

.executiveContent p {
	line-height: 0.6cm;
	color: black;
	font-family: 'Arial';
	font-size: 13px;
}

.secondHeader {
	color: #50ab92;
	font-family: 'Arial';
	font-weight: 700;
	font-size: 15px;
}

.tableheader {
	color: #50ab92;
	font-family: 'ArialItalic';
}

.sumryTxt {
	color: black;
	font-family: 'Arial';
	font-size: 13px;
}

.executivesumryTable table {
	width: 100%;
	border: 1px solid black;
	border-collapse: collapse;
	border-spacing: 0;
}

.executivesumryTable th {
	border: 0.5px solid black;
	border-collapse: collapse;
	font-family: 'Arial';
	font-size: 14px;
	padding: 10px;
}

.footertxt {
	margin-top: 118px;
	font-size: 17px;
	color: #50ab92;
	font-family: 'Arial';
	font-weight: 700;
	text-align: center;
}

.footerImages {
	margin-left: 122px;
}

.footerImages img {
	margin-left: 17px;
}

.executivesumryTable td {
	color: black;
	font-family: 'Arial';
	font-weight: 100;
	border: 1px solid black;
	border-collapse: collapse;
	font-size: 14px;
}

.pageNumber:after {
	content: counter(page);
	counter-increment: page;
}

.executivesumryTable td,
.executivesumryTable tr,
.executivesumryTable th {
	color: black;
	text-align: center;
}

.imgtxtSectn {
	position: relative;
}

.switchImgTxt p {
	font-family: 'Arial';
	font-size: 12px;
	color: 000;
	color: black;
	position: absolute;
	top: 9px;
	left: 278px;
}

.image_two img {
	max-width: 100%;
	width: 220px;
	margin-left: 227px;
}
