.headerText {
	color: #294d8f;
	font-weight: bold;
}
.label {
	font-weight: 700;
	font-size: 22px;
	color: #294d8f;
}
.passWordStrongList {
	padding: 0px;
}
.passWordStrongList li {
	font-family: 'Roboto';
	position: relative;
	padding: 4px 0px;
	font-size: 12px;
}
.passWordStrongList li span:nth-child(2) {
	margin-top: 0px;
	font-size: 20px;
	position: absolute;
	right: 0;
	top: 0px;
}
.secondary_btnTwo {
	background-color: #ffffff;
	color: #294d8f;
	border: #8a9192 1px solid;
	padding: 8px 15px;
	font-size: 14px;
	border-radius: 6px;
	font-weight: 500;
	line-height: 1.5;
	margin: 2px;
	position: relative;
	display: inline-block;
	text-align: center;
	min-width: 80px;
}
