#dropdown {
	background-color: #294d8f;
	border: 1px solid #294d8f;
	width: 110px;
	text-align: left;
	display: flex;
	gap: 12px;
	align-items: center;
	text-transform: capitalize;
}
