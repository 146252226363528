.custom_container_table {
	max-width: 794px;
	width: 100%;
	margin: 0px 0px 0px 12px;
}

.table_header {
	font-size: 16px;
	color: #294d8f;
	font-family: 'Robotomedium';
}

.teble_head {
	background-color: #294d8f;
	color: aliceblue;
}

.hr_line {
	height: 1px;
	width: 180px;
	border-width: 0;
	color: #3b7e80;
	background-color: #3b7e80;

	opacity: 1;
}

.footer_points {
	font-size: 11px;
	line-height: 0.5cm;
	list-style-type: none;
	font-family: 'Robotomedium';
	color: black;
}

.footer_images_table {
	margin-left: 0px;
	margin-top: 12px;
	display: flex;
	align-items: self-end;
}

.footer_images_table {
	margin-left: 5px;
	margin-top: 5px;
}

.footer_images_text {
	font-size: 8px;
	color: #294d8f;
}

.bottom_div {
	margin-top: 67px;
}

.footer_points_scp {
	font-size: 11px;
	line-height: 20px;
	list-style-type: none;
	font-family: 'Arial';
	color: black;
}

.footer_points_scp sup {
	font-family: 'Arial';
	color: black;
	font-size: 0.8em;
}

.SCPImprovment td {
	text-align: center;
	font-family: 'Arial';
	color: black;
}

.imgtxtSectn {
	position: relative;
}

.switchImgTxt p {
	font-family: 'Arial';
	font-size: 12px;
	color: 000;
	color: black;
	position: absolute;
	top: 9px;
	left: 278px;
}

.image_two img {
	max-width: 100%;
	width: 250px;
	margin-left: 257px;
	margin-bottom: 20px;
}

.table_header {
	color: #294d8f;
	font-size: 15px;
}
