.login {
	padding: 67px 20px 157px 0px;
}
.login h1 {
	font-weight: 700;
	font-size: 24px;
	color: #294d8f;
}
.login_form label {
	font-weight: 600;
	font-size: 14px;
	color: #294d8f;
}
