.iarCard {
	background: #fff;
	box-shadow: 0px 0px 10px -5px rgb(0 0 0 / 30%);
	border-radius: 10px;
	border: 1px solid #00aa45;
	padding: 24px;
	height: 146px;
}
.iarCard h2 {
	color: #294d8f;
	font-size: 26px;
	font-family: 'RobotoBold';
	margin-bottom: 10px;
}
.iarCard span {
	color: #5a5a5a !important;
	font-size: 13px;
	font-family: 'Robotomedium';
	font-weight: 100;
}
.iarCard span {
	color: #5a5a5a;
	font-size: 12px;
	font-weight: 500;
	padding-top: 8px;
	font-family: 'Robotomedium';
}
.iarCard h3 {
	color: #294d8f;
	font-size: 17px;
	text-transform: capitalize;
	font-family: 'RobotoBold';
}
.iarCard p {
	color: #6d6d6d;
	font-size: 14px;
	font-weight: 400;
	margin: 0;
}
.addnewcomp {
	min-width: 300px !important;
	border-radius: 50px !important;
	border: 1px solid #294d8f !important;
	background-color: #e9faff !important ;
	text-align: start !important;
}
.addnewcomp img {
	width: 20px !important;
	margin-left: 15px;
}
@media only screen and (max-width: 992px) {
	.iarCard {
		height: 100%;
	}
}
@media only screen and (max-width: 767px) {
	a.addnewcomp {
		width: 100%;
	}
	.PtagDot {
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		padding: 0px 0px;
		padding-bottom: 10px;
		color: #6d6d6d;
		font-size: 10px !important;
		font-weight: 400;
		display: -webkit-box;
		max-width: 200px;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		height: 30px;
		line-height: 16px;
	}
}
@media only screen and (max-width: 400px) {
	.PtagDot {
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		padding: 0px 0px;
		padding-bottom: 10px;
		color: #6d6d6d;
		font-size: 10px !important;
		font-weight: 400;
		display: -webkit-box;
		max-width: 200px;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		height: 30px;
		line-height: 16px;
	}
}
@media only screen and (max-width: 360px) {
	.PtagDot {
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		padding: 0px 0px;
		padding-bottom: 10px;
		color: #6d6d6d;
		font-size: 10px;
		font-weight: 400;
		display: -webkit-box;
		max-width: 200px;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		height: 30px;
	}
}
