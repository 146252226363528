.upload {
    padding: 35px 20px;
}

.header {
    color: #5A5A5A;
    font-size: 20px;
    font-family: 'Robotomedium';
    font-weight: 600;
    margin-bottom: 3px;
}

.header_hr {
    height: 3px;
    border-top: 2px solid #5A5A5A;
    margin: 0px;
}

.notification_hr {
    height: 3px;
    border-top: 2px solid #5A5A5A;
    opacity: 0.1;
    margin: 0px;
}

.notificationMaintxt p {
    padding: 0px;
    margin: 0px;
}

.notificationMaintxt {
    color: #5A5A5A;
    font-size: 16px;
    font-family: 'Roboto';
    font-weight: 600;
    padding: 5px 0px;
}
.notificationSubtxt p{
    margin-bottom: 8px;
}
.notificationSubtxt {
    font-size: 13px;
    color: #6D6D6D;
    font-family: 'Roboto';
    padding: 0px 20px 0px 0px;
    width: 76%;
}

.notificationSubtxtDate span {
    display: block;
    font-family: 'Roboto';
    font-size: 12px;
    color: #8B8B8B;
}


.notificationWrapper ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

}